import React, { useEffect, Suspense } from 'react';
import ZwiftHub from './components/ZwiftHub';
import LoadingScreen from './components/LoadingScreen';
import { preloadAuth, preloadFirestore, useFirebaseApp } from 'reactfire';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

// working code for old google analytics I use ga web +app in the index instead
// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-161432983-1');
// ReactGA.pageview(window.location.pathname + window.location.search);

const theme = createMuiTheme({
	palette: {
		primary: { main: '#fc6719', contrastText: '#fff' },
		secondary: { main: '#0093d1', contrastText: '#fff' }
	}
});

function preloadLibraries(firebaseApp) {
	preloadAuth({ firebaseApp });
	preloadFirestore({
		firebaseApp,
		setup: (firestore) => {
			return firestore().enablePersistence();
		}
	});
}

function App() {
	const firebaseApp = useFirebaseApp();
	useEffect(() => preloadLibraries(firebaseApp), [ firebaseApp ]);

	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<LoadingScreen />}>
				<ZwiftHub />
			</Suspense>
		</ThemeProvider>
	);
}

export default App;
