// Video for reactfire: https://www.youtube.com/watch?v=Mi9aKDcpRYA

import React, { useState } from 'react';
import SideDrawer from './SideDrawer';
import { WorldFilterProvider } from '../contexts/WorldFilter.context';
import Hidden from '@material-ui/core/Hidden';
import BottomNav from './BottomNav';
import ShutDown from './ShutDown';

function ZwiftHub() {
	// mobile view toggle for Filter Drawer
	const [ mobileOpen, setMobileOpen ] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	// mobile view toggle for Search Drawer
	const [ mobileOpenSearch, setMobileOpenSearch ] = useState(false);

	const handleDrawerToggleSearch = () => {
		setMobileOpenSearch(!mobileOpenSearch);
	};

	// toggle for Achievment Drawer

	const [ achievementOpen, setAchievementOpen ] = useState(false);

	const handleAchievementDrawerToggle = () => {
		setAchievementOpen(!achievementOpen);
	};

	// toggle for Achievment DrawerRun

	const [ achievementOpenRun, setAchievementOpenRun ] = useState(false);

	const handleAchievementDrawerToggleRun = () => {
		setAchievementOpenRun(!achievementOpenRun);
	};

	// handling of badgedialog

	const [ openBadgeDialog, setOpenBadgeDialog ] = useState(false);

	const handleClickOpenBadgeDialog = () => {
		setOpenBadgeDialog(true);
	};

	const handleClickCloseBadgeDialog = () => {
		setOpenBadgeDialog(false);
	};

	// handling of badgedialogRun

	const [ openBadgeDialogRun, setOpenBadgeDialogRun ] = useState(false);

	const handleClickOpenBadgeDialogRun = () => {
		setOpenBadgeDialogRun(true);
	};

	const handleClickCloseBadgeDialogRun = () => {
		setOpenBadgeDialogRun(false);
	};

	// toggle for Shut

	const [ shutDown, setShutDown ] = useState(false);

	const handleShutDown = () => {
		setShutDown(!shutDown);
	};

	return (
		<WorldFilterProvider>
			<SideDrawer
				mobileOpen={mobileOpen}
				setMobileOpen={setMobileOpen}
				handleDrawerToggle={handleDrawerToggle}
				mobileOpenSearch={mobileOpenSearch}
				setMobileOpenSearch={setMobileOpenSearch}
				handleDrawerToggleSearch={handleDrawerToggleSearch}
				achievementOpen={achievementOpen}
				setAchievementOpen={setAchievementOpen}
				handleAchievementDrawerToggle={handleAchievementDrawerToggle}
				openBadgeDialog={openBadgeDialog}
				setOpenBadgeDialog={setOpenBadgeDialog}
				handleClickCloseBadgeDialog={handleClickCloseBadgeDialog}
				handleClickOpenBadgeDialog={handleClickOpenBadgeDialog}
				openBadgeDialogRun={openBadgeDialogRun}
				setOpenBadgeDialogRun={setOpenBadgeDialogRun}
				handleClickCloseBadgeDialogRun={handleClickCloseBadgeDialogRun}
				handleClickOpenBadgeDialogRun={handleClickOpenBadgeDialogRun}
				achievementOpenRun={achievementOpenRun}
				setAchievementOpenRun={setAchievementOpenRun}
				handleAchievementDrawerToggleRun={handleAchievementDrawerToggleRun}
				shutDown={shutDown}
				setShutDown={setShutDown}
				handleShutDown={handleShutDown}
			/>
			<Hidden mdUp>
				<BottomNav
					mobileOpen={mobileOpen}
					setMobileOpen={setMobileOpen}
					handleDrawerToggle={handleDrawerToggle}
					mobileOpenSearch={mobileOpenSearch}
					setMobileOpenSearch={setMobileOpenSearch}
					handleDrawerToggleSearch={handleDrawerToggleSearch}
					achievementOpen={achievementOpen}
					setAchievementOpen={setAchievementOpen}
					handleAchievementDrawerToggle={handleAchievementDrawerToggle}
					openBadgeDialog={openBadgeDialog}
					setOpenBadgeDialog={setOpenBadgeDialog}
					handleClickCloseBadgeDialog={handleClickCloseBadgeDialog}
					handleClickOpenBadgeDialog={handleClickOpenBadgeDialog}
					openBadgeDialogRun={openBadgeDialogRun}
					setOpenBadgeDialogRun={setOpenBadgeDialogRun}
					handleClickCloseBadgeDialogRun={handleClickCloseBadgeDialogRun}
					handleClickOpenBadgeDialogRun={handleClickOpenBadgeDialogRun}
					achievementOpenRun={achievementOpenRun}
					setAchievementOpenRun={setAchievementOpenRun}
					handleAchievementDrawerToggleRun={handleAchievementDrawerToggleRun}
				/>
			</Hidden>
			<ShutDown shutDown={shutDown} setShutDown={setShutDown} handleShutDown={handleShutDown} />
		</WorldFilterProvider>
	);
}

export default ZwiftHub;
