import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	sprintchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function SprintInnsbruckFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleSprint, chosensprint } = useContext(WorldFilterContext);

	// Definition of the toggle states for the sprints

	const [ isInnsbruckSprint, toggleIsInnsbruckSprint ] = useToggle(chosensprint.includes('Innsbruck Sprint'));
	const [ isInnsbruckSprintRev, toggleIsInnsbruckSprintRev ] = useToggle(
		chosensprint.includes('Innsbruck Sprint Rev.')
	);

	return (
		<div className={classes.root}>
			<div className={classes.sprintchips}>
				<Chip
					className={classes.chip}
					label="Innsbruck Sprint"
					onClick={() => {
						toggleSprint('Innsbruck Sprint');
						toggleIsInnsbruckSprint();
					}}
					variant={isInnsbruckSprint ? 'default' : 'outlined'}
					color={isInnsbruckSprint ? 'primary' : 'default'}
					style={{ color: isInnsbruckSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Inns. Sprint Rev."
					onClick={() => {
						toggleSprint('Innsbruck Sprint Rev.');
						toggleIsInnsbruckSprintRev();
					}}
					variant={isInnsbruckSprintRev ? 'default' : 'outlined'}
					color={isInnsbruckSprintRev ? 'primary' : 'default'}
					style={{ color: isInnsbruckSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(SprintInnsbruckFilter);
