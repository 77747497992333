import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
	progressIcon: {
		position: 'absolute'
	}
}));

function ProgressionIconTo(props) {
	const classes = useStyles();

	const trophyColor =
		props.achievmentpercent === 0
			? '#d3d3d3'
			: props.achievmentpercent < 50 ? '#CD7F32' : props.achievmentpercent < 100 ? '#848482' : '#f4c117';

	return (
		<Avatar style={{ backgroundColor: 'transparent' }}>
			<CircularProgress
				className={classes.progressIcon}
				size={35}
				style={{ color: '#d3d3d3' }}
				variant="static"
				value={100}
			/>
			<CircularProgress
				className={classes.progressIcon}
				size={35}
				color="secondary"
				variant="static"
				value={props.achievmentpercent}
			/>

			<EmojiEventsIcon className={classes.progressIcon} style={{ color: trophyColor }} />
		</Avatar>
	);
}
export default memo(ProgressionIconTo);
