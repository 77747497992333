import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	lapchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function LapParisFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleLap, chosenlap } = useContext(WorldFilterContext);

	// Definition of the toggle states for the Laps

	const [ isChampsElysees, toggleIsChampsElysees ] = useToggle(chosenlap.includes('Champs-Élysées'));
	const [ isChampsElyseesRev, toggleIsChampsElyseesRev ] = useToggle(chosenlap.includes('Champs-Élysées Rev.'));

	return (
		<div className={classes.root}>
			<div className={classes.lapchips}>
				<Chip
					className={classes.chip}
					label="Champs-Élysées"
					onClick={() => {
						toggleLap('Champs-Élysées');
						toggleIsChampsElysees();
					}}
					variant={isChampsElysees ? 'default' : 'outlined'}
					color={isChampsElysees ? 'primary' : 'default'}
					style={{ color: isChampsElysees ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Champs Rev."
					onClick={() => {
						toggleLap('Champs-Élysées Rev.');
						toggleIsChampsElyseesRev();
					}}
					variant={isChampsElyseesRev ? 'default' : 'outlined'}
					color={isChampsElyseesRev ? 'primary' : 'default'}
					style={{ color: isChampsElyseesRev ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(LapParisFilter);
