import React, { memo, useContext, lazy, Suspense } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NavigationList from './NavigationList';
import TopBar from './TopBar';
import zwifthub_logo from '../media/zwifthub_logo.png';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import SearchDrawer from './SearchDrawer.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { WorldFilterContext } from '../contexts/WorldFilter.context';

const RouteGrid = lazy(() => import('./RouteGrid'));
const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex'
	},
	drawer: {
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			flexShrink: 0
		}
	},
	drawerbottom: {
		flexShrink: 0
	},

	toolbar: theme.mixins.toolbar,

	drawerPaper: {
		width: drawerWidth
	},

	drawerPaperbottom: {},

	appBar: {
		[theme.breakpoints.up('md')]: {
			width: drawerWidth
			// zIndex: theme.zIndex.drawer + 1
		}
	},

	imagewraper: {
		display: 'flex',
		justifyContent: 'center'
	},

	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	}
}));

function SideDrawer(props) {
	const { container } = props;
	const classes = useStyles();
	const theme = useTheme();

	//context import
	const { chosensport } = useContext(WorldFilterContext);

	const drawer = (
		<div>
			<Hidden smDown>
				<div className={classes.toolbar}>
					<AppBar
						color={chosensport === 'cycling' ? 'primary' : 'secondary'}
						position="absolute"
						className={classes.appBar}
					>
						<Toolbar className={classes.imagewraper}>
							<img src={zwifthub_logo} height="26px" alt="Logo" />
						</Toolbar>
					</AppBar>
				</div>
			</Hidden>
			<Divider />
			<NavigationList
				shutDown={props.shutDown}
				setShutDown={props.setShutDown}
				handleShutDown={props.handleShutDown}
			/>
		</div>
	);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<TopBar
				achievementOpen={props.achievementOpen}
				setAchievementOpen={props.setAchievementOpen}
				handleAchievementDrawerToggle={props.handleAchievementDrawerToggle}
				openBadgeDialog={props.openBadgeDialog}
				setOpenBadgeDialog={props.setOpenBadgeDialog}
				handleClickCloseBadgeDialog={props.handleClickCloseBadgeDialog}
				handleClickOpenBadgeDialog={props.handleClickOpenBadgeDialog}
				openBadgeDialogRun={props.openBadgeDialogRun}
				setOpenBadgeDialogRun={props.setOpenBadgeDialogRun}
				handleClickCloseBadgeDialogRun={props.handleClickCloseBadgeDialogRun}
				handleClickOpenBadgeDialogRun={props.handleClickOpenBadgeDialogRun}
				achievementOpenRun={props.achievementOpenRun}
				setAchievementOpenRun={props.setAchievementOpenRun}
				handleAchievementDrawerToggleRun={props.handleAchievementDrawerToggleRun}
			/>
			<nav aria-label="filter and sort">
				<Hidden mdUp implementation="css">
					<Drawer
						className={classes.drawer}
						container={container}
						variant="temporary"
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={props.mobileOpen}
						onClose={props.handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
					<Drawer
						className={classes.drawerbottom}
						container={container}
						variant="temporary"
						anchor={'top'}
						open={props.mobileOpenSearch}
						onClose={props.handleDrawerToggleSearch}
						classes={{
							paper: classes.drawerPaperbottom
						}}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						<SearchDrawer
							mobileOpenSearch={props.mobileOpenSearch}
							setMobileOpenSearch={props.setMobileOpenSearch}
							handleDrawerToggleSearch={props.handleDrawerToggleSearch}
						/>
					</Drawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<Drawer
						className={classes.drawer}
						classes={{
							paper: classes.drawerPaper
						}}
						variant="permanent"
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<Suspense fallback={<CircularProgress disableShrink size={50} />}>
					<RouteGrid />
				</Suspense>
				<Hidden mdUp implementation="css">
					<div className={classes.toolbar} />
				</Hidden>
			</main>
		</div>
	);
}

export default memo(SideDrawer);
