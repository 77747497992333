import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	sprintchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function SprintFranceFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleSprint, chosensprint } = useContext(WorldFilterContext);

	// Definition of the toggle states for the sprints

	const [ isBallonSprint, toggleIsBallonSprint ] = useToggle(chosensprint.includes('Ballon Sprint'));
	const [ isBallonSprintRev, toggleIsBallonSprintRev ] = useToggle(chosensprint.includes('Ballon Sprint Rev.'));
	const [ isMarinaSprint, toggleIsMarinaSprint ] = useToggle(chosensprint.includes('Marina Sprint'));
	const [ isMarinaSprintRev, toggleIsMarinaSprintRev ] = useToggle(chosensprint.includes('Marina Sprint Rev.'));
	const [ isPaveSprint, toggleIsPaveSprint ] = useToggle(chosensprint.includes('Pavé Sprint'));
	const [ isPaveSprintRev, toggleIsPaveSprintRev ] = useToggle(chosensprint.includes('Pavé Sprint Rev.'));

	return (
		<div className={classes.root}>
			<div className={classes.sprintchips}>
				<Chip
					className={classes.chip}
					label="Ballon Sprint"
					onClick={() => {
						toggleSprint('Ballon Sprint');
						toggleIsBallonSprint();
					}}
					variant={isBallonSprint ? 'default' : 'outlined'}
					color={isBallonSprint ? 'primary' : 'default'}
					style={{ color: isBallonSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Ballon Rev."
					onClick={() => {
						toggleSprint('Ballon Sprint Rev.');
						toggleIsBallonSprintRev();
					}}
					variant={isBallonSprintRev ? 'default' : 'outlined'}
					color={isBallonSprintRev ? 'primary' : 'default'}
					style={{ color: isBallonSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Marina Sprint"
					onClick={() => {
						toggleSprint('Marina Sprint');
						toggleIsMarinaSprint();
					}}
					variant={isMarinaSprint ? 'default' : 'outlined'}
					color={isMarinaSprint ? 'primary' : 'default'}
					style={{ color: isMarinaSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Marina Rev."
					onClick={() => {
						toggleSprint('Marina Sprint Rev.');
						toggleIsMarinaSprintRev();
					}}
					variant={isMarinaSprintRev ? 'default' : 'outlined'}
					color={isMarinaSprintRev ? 'primary' : 'default'}
					style={{ color: isMarinaSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Pavé Sprint"
					onClick={() => {
						toggleSprint('Pavé Sprint');
						toggleIsPaveSprint();
					}}
					variant={isPaveSprint ? 'default' : 'outlined'}
					color={isPaveSprint ? 'primary' : 'default'}
					style={{ color: isPaveSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Pavé Rev."
					onClick={() => {
						toggleSprint('Pavé Sprint Rev.');
						toggleIsPaveSprintRev();
					}}
					variant={isPaveSprintRev ? 'default' : 'outlined'}
					color={isPaveSprintRev ? 'primary' : 'default'}
					style={{ color: isPaveSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(SprintFranceFilter);
