import React, { memo, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import zwifthub_logo from '../media/zwifthub_logo.png';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useUser, useAuth } from 'reactfire';
import { WorldFilterContext } from '../contexts/WorldFilter.context';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// Configure FirebaseUI.
const uiConfig = {
	// Popup signin flow rather than redirect flow.
	signInFlow: 'popup',
	// Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
	signInSuccessUrl: '/signedIn',
	// We will display Google and Facebook as auth providers.
	signInOptions: [ firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.EmailAuthProvider.PROVIDER_ID ],
	credentialHelper: 'none',
	// Terms of service url/callback.
	tosUrl: '/tos.html',
	// Privacy policy url/callback.
	privacyPolicyUrl: '/privacypolicy.html'
};

const useStyles = makeStyles((theme) => ({
	container: {
		padding: '5px 0',
		margin: 'auto',
		display: 'flex',
		flexDirection: 'column',
		// justifyContent: 'space-evenly',
		alignItems: 'center',
		width: '250px'
	},
	logo: {
		marginTop: '-5px',
		padding: '20px 0 15px',
		width: '100%',
		backgroundColor: '#fc6719',
		textAlign: 'center'
	},
	text: {
		padding: '20px 10px 10px'
	},
	displaysettings: {
		padding: '15px 5px 5px'
	},
	logoutbutton1: {
		width: '180px',
		margin: '30px 5px 10px',
		color: '#fff'
	},
	switchgroup: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	formgroupset: {
		paddingRight: '15px'
	}
}));

const LogInOut = (props) => {
	const classes = useStyles();
	const auth = useAuth();
	const user = useUser();

	// Import from context
	const { docRef, displaymiles, hidexp, hideleadin } = useContext(WorldFilterContext);

	//switch functions write to firestore
	const switchtomi = () =>
		docRef.update({
			unitdisplay: 'miles'
		});

	const switchtokm = () =>
		docRef.update({
			unitdisplay: 'kms'
		});

	const switchonhidexp = () =>
		docRef.update({
			xpdisplay: 'hide'
		});

	const switchoffhidexp = () =>
		docRef.update({
			xpdisplay: 'show'
		});

	const switchonhideleadin = () =>
		docRef.update({
			leadindisplay: 'hide'
		});

	const switchoffhideleadin = () =>
		docRef.update({
			leadindisplay: 'show'
		});

	//handleswitches
	const handleswitchtomi = (event) => {
		displaymiles ? switchtokm() : switchtomi();
	};
	const handleswitchtokm = (event) => {
		displaymiles ? switchtokm() : switchtomi();
	};
	const handleswitchhidexp = (event) => {
		hidexp ? switchoffhidexp() : switchonhidexp();
	};
	const handleswitchhideleadin = (event) => {
		hideleadin ? switchoffhideleadin() : switchonhideleadin();
	};

	//logout

	const logout = () =>
		auth.signOut().then(function() {
			window.location.reload(true);
		});

	if (user === null) {
		return (
			<div className={classes.container}>
				<div className={classes.logo}>
					<img src={zwifthub_logo} height="26px" alt="Logo" />
				</div>

				<Typography align="center" className={classes.text}>
					Sign in to track your achievements and adjust display settings
				</Typography>
				<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
			</div>
		);
	}

	return (
		<div className={classes.container}>
			<div className={classes.logo}>
				<img src={zwifthub_logo} height="26px" alt="Logo" />
			</div>
			<Typography align="center" color="primary" variant="h6" className={classes.displaysettings}>
				Settings
			</Typography>

			<FormControl component="fieldset">
				<FormGroup className={classes.formgroupset}>
					<FormControlLabel
						className={classes.switchgroup}
						control={<Switch checked={!displaymiles} onChange={handleswitchtokm} name="km" />}
						label="Kilometers/Meters"
						labelPlacement="start"
					/>
					<FormControlLabel
						className={classes.switchgroup}
						control={<Switch checked={displaymiles} onChange={handleswitchtomi} name="miles" />}
						label="Miles/Feet"
						labelPlacement="start"
					/>
					<FormControlLabel
						className={classes.switchgroup}
						control={<Switch checked={!hideleadin} onChange={handleswitchhideleadin} name="leadin" />}
						label="Lead-ins"
						labelPlacement="start"
					/>
					<FormControlLabel
						className={classes.switchgroup}
						control={<Switch checked={!hidexp} onChange={handleswitchhidexp} name="antoine" />}
						label="Achievement XP"
						labelPlacement="start"
					/>
				</FormGroup>
			</FormControl>

			<Button className={classes.logoutbutton1} variant="contained" color="secondary" onClick={logout}>
				Log out
			</Button>
		</div>
	);
};

export default memo(LogInOut);
