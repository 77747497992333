import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import zwifthub_logo from '../media/zwifthub_logo.png';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: '100px',
		margin: 'auto',
		display: 'flex',
		flexDirection: 'column',
		// justifyContent: 'space-evenly',
		alignItems: 'center',
		maxWidth: '460px'
	},
	background: {
		backgroundColor: '#fc6719',
		padding: '20px',
		marginBottom: '30px'
	}
}));

function LoadingScreen() {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<div className={classes.background}>
				<img src={zwifthub_logo} height="40px" alt="Logo" />
			</div>

			<CircularProgress size={100} />
		</div>
	);
}

export default LoadingScreen;
