import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import cloudinary from 'cloudinary-core';

const cloudinaryCore = new cloudinary.Cloudinary({ cloud_name: 'zwifthub' });

const useStyles = makeStyles((theme) => ({
	card: {
		maxWidth: '500px'
	},
	media: {
		height: 0,
		paddingTop: '68.8%'
	}
}));

export default function About(props) {
	const classes = useStyles();
	const handleCloseAbout = () => {
		props.setOpenAbout(false);
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(
		() => {
			if (props.openAbout) {
				const { current: descriptionElement } = descriptionElementRef;
				if (descriptionElement !== null) {
					descriptionElement.focus();
				}
			}
		},
		[ props.openAbout ]
	);

	return (
		<div>
			<Dialog
				open={props.openAbout}
				onClose={handleCloseAbout}
				maxWidth="md"
				scroll="paper"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">About</DialogTitle>
				<DialogContent dividers={true}>
					<Card className={classes.card}>
						<CardMedia
							className={classes.media}
							image={cloudinaryCore.url('mockup_zwifthub')}
							title="ZwiftHub responsive"
						/>
					</Card>
					<Typography
						style={{ color: 'rgba(0, 0, 0, 0.54)' }}
						component={'span'}
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
					>
						<p>
							<strong>Disclaimer</strong>
						</p>{' '}
						<p>
							ZwiftHub is a responsive web app created by the hobby web developer and Zwift enthusiast
							Tobias Fenney. It is not affiliated with the Zwift Inc. (www.zwift.com) in any way.{' '}
						</p>
						<p>
							Special THANKS to Jonathon Levie (Zwift Hype - www.zwifthype.com) for your input and
							support!
						</p>
						<p>Have fun using ZwiftHub and Ride On!</p>
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseAbout} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
