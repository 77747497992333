import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	lapchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function LapWatopiaFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleLap, chosenlap } = useContext(WorldFilterContext);

	// Definition of the toggle states for the Laps

	const [ isHillyLoop, toggleIsHillyLoop ] = useToggle(chosenlap.includes('Hilly Loop'));
	const [ isVolcanoCircuit, toggleIsVolcanoCircuit ] = useToggle(chosenlap.includes('Volcano Circuit'));
	const [ isVolcanoCircuitRev, toggleIsVolcanoCircuitRev ] = useToggle(chosenlap.includes('Volcano Circuit Rev.'));
	const [ isJungleLoop, toggleIsJungleLoop ] = useToggle(chosenlap.includes('Jungle Loop'));

	return (
		<div className={classes.root}>
			<div className={classes.lapchips}>
				<Chip
					className={classes.chip}
					label="Hilly Loop"
					onClick={() => {
						toggleLap('Hilly Loop');
						toggleIsHillyLoop();
					}}
					variant={isHillyLoop ? 'default' : 'outlined'}
					color={isHillyLoop ? 'primary' : 'default'}
					style={{ color: isHillyLoop ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Volcano Circuit"
					onClick={() => {
						toggleLap('Volcano Circuit');
						toggleIsVolcanoCircuit();
					}}
					variant={isVolcanoCircuit ? 'default' : 'outlined'}
					color={isVolcanoCircuit ? 'primary' : 'default'}
					style={{ color: isVolcanoCircuit ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Volcano Cir. Rev."
					onClick={() => {
						toggleLap('Volcano Circuit Rev.');
						toggleIsVolcanoCircuitRev();
					}}
					variant={isVolcanoCircuitRev ? 'default' : 'outlined'}
					color={isVolcanoCircuitRev ? 'primary' : 'default'}
					style={{ color: isVolcanoCircuitRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Jungle Loop"
					onClick={() => {
						toggleLap('Jungle Loop');
						toggleIsJungleLoop();
					}}
					variant={isJungleLoop ? 'default' : 'outlined'}
					color={isJungleLoop ? 'primary' : 'default'}
					style={{ color: isJungleLoop ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(LapWatopiaFilter);
