import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	komchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function KomWatopiaFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleKom, chosenkom } = useContext(WorldFilterContext);

	// Definition of the toggle states for the Koms

	const [ isAlpeduZwift, toggleIsAlpeduZwift ] = useToggle(chosenkom.includes('Alpe du Zwift'));
	const [ isEpicKom, toggleIsEpicKom ] = useToggle(chosenkom.includes('Epic KOM'));
	const [ isEpicKomRev, toggleIsEpicKomRev ] = useToggle(chosenkom.includes('Epic KOM Rev.'));
	const [ isFuegoFlatsRev, toggleIsFuegoFlatsRev ] = useToggle(chosenkom.includes('Fuego Flats Rev.'));
	const [ isTitansGroveKom, toggleIsTitansGroveKom ] = useToggle(chosenkom.includes('Titans Grove KOM'));
	const [ isTitansGroveKomRev, toggleIsTitansGroveKomRev ] = useToggle(chosenkom.includes('Titans Grove KOM Rev.'));
	const [ isVolcanoKom, toggleIsVolcanoKom ] = useToggle(chosenkom.includes('Volcano KOM'));
	const [ isZwiftKom, toggleIsZwiftKom ] = useToggle(chosenkom.includes('Zwift KOM'));
	const [ isZwiftKomRev, toggleIsZwiftKomRev ] = useToggle(chosenkom.includes('Zwift KOM Rev.'));
	const [ isMayanMountainsideKom, toggleIsMayanMountainsideKom ] = useToggle(chosenkom.includes('Mayan Mountainside KOM'));
	const [ isItzaKom, toggleIsItzaKom ] = useToggle(chosenkom.includes('Itza KOM'));
	const [ isRadioTowerKom, toggleIsRadioTowerKom ] = useToggle(chosenkom.includes('Radio Tower KOM'));
	return (
		<div className={classes.root}>
			<div className={classes.komchips}>
				<Chip
					className={classes.chip}
					label="Alpe du Zwift"
					onClick={() => {
						toggleKom('Alpe du Zwift');
						toggleIsAlpeduZwift();
					}}
					variant={isAlpeduZwift ? 'default' : 'outlined'}
					color={isAlpeduZwift ? 'primary' : 'default'}
					style={{ color: isAlpeduZwift ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Epic KOM"
					onClick={() => {
						toggleKom('Epic KOM');
						toggleIsEpicKom();
					}}
					variant={isEpicKom ? 'default' : 'outlined'}
					color={isEpicKom ? 'primary' : 'default'}
					style={{ color: isEpicKom ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Epic KOM Rev."
					onClick={() => {
						toggleKom('Epic KOM Rev.');
						toggleIsEpicKomRev();
					}}
					variant={isEpicKomRev ? 'default' : 'outlined'}
					color={isEpicKomRev ? 'primary' : 'default'}
					style={{ color: isEpicKomRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Fuego Flats Rev."
					onClick={() => {
						toggleKom('Fuego Flats Rev.');
						toggleIsFuegoFlatsRev();
					}}
					variant={isFuegoFlatsRev ? 'default' : 'outlined'}
					color={isFuegoFlatsRev ? 'primary' : 'default'}
					style={{ color: isFuegoFlatsRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Titans Grove"
					onClick={() => {
						toggleKom('Titans Grove KOM');
						toggleIsTitansGroveKom();
					}}
					variant={isTitansGroveKom ? 'default' : 'outlined'}
					color={isTitansGroveKom ? 'primary' : 'default'}
					style={{ color: isTitansGroveKom ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Titans Gro. Rev."
					onClick={() => {
						toggleKom('Titans Grove KOM Rev.');
						toggleIsTitansGroveKomRev();
					}}
					variant={isTitansGroveKomRev ? 'default' : 'outlined'}
					color={isTitansGroveKomRev ? 'primary' : 'default'}
					style={{ color: isTitansGroveKomRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Volcano KOM"
					onClick={() => {
						toggleKom('Volcano KOM');
						toggleIsVolcanoKom();
					}}
					variant={isVolcanoKom ? 'default' : 'outlined'}
					color={isVolcanoKom ? 'primary' : 'default'}
					style={{ color: isVolcanoKom ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Zwift KOM"
					onClick={() => {
						toggleKom('Zwift KOM');
						toggleIsZwiftKom();
					}}
					variant={isZwiftKom ? 'default' : 'outlined'}
					color={isZwiftKom ? 'primary' : 'default'}
					style={{ color: isZwiftKom ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Zwift KOM Rev."
					onClick={() => {
						toggleKom('Zwift KOM Rev.');
						toggleIsZwiftKomRev();
					}}
					variant={isZwiftKomRev ? 'default' : 'outlined'}
					color={isZwiftKomRev ? 'primary' : 'default'}
					style={{ color: isZwiftKomRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Mayan Mountainside KOM"
					onClick={() => {
						toggleKom('Mayan Mountainside KOM');
						toggleIsMayanMountainsideKom();
					}}
					variant={isMayanMountainsideKom ? 'default' : 'outlined'}
					color={isMayanMountainsideKom ? 'primary' : 'default'}
					style={{ color: isMayanMountainsideKom ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Itza KOM"
					onClick={() => {
						toggleKom('Itza KOM');
						toggleIsItzaKom();
					}}
					variant={isItzaKom ? 'default' : 'outlined'}
					color={isItzaKom ? 'primary' : 'default'}
					style={{ color: isItzaKom ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Radio Tower KOM"
					onClick={() => {
						toggleKom('Radio Tower KOM');
						toggleIsRadioTowerKom();
					}}
					variant={isRadioTowerKom ? 'default' : 'outlined'}
					color={isRadioTowerKom ? 'primary' : 'default'}
					style={{ color: isRadioTowerKom ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(KomWatopiaFilter);
