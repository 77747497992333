import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import cloudinary from 'cloudinary-core';
import { WorldFilterContext } from '../contexts/WorldFilter.context';
import { AuthCheck } from 'reactfire';
import useToggle from '../hooks/useToggleState';
import Tooltip from '@material-ui/core/Tooltip';

const cloudinaryCore = new cloudinary.Cloudinary({ cloud_name: 'zwifthub' });

const useStyles = makeStyles({
	media: {
		height: 0,
		paddingTop: '100%',
		filter: 'grayscale(100%)',
		opacity: '50%'
	},
	mediadone: {
		height: 0,
		paddingTop: '100%'
	},

	text: {
		margin: '0.5rem 0 0.5rem'
	}
});

function BadgeCard(props) {
	const classes = useStyles();

	// Import from context
	const { userbadges, docRef } = useContext(WorldFilterContext);

	const nameBadgeDone = 'Badge' + props.badgeid;

	//BadgeDoneHandling
	const [ isDone, toggleIsDone ] = useToggle(userbadges.includes(nameBadgeDone));

	// Firebasestuff
	const setFirebaseNotDone = () =>
		docRef.update({
			[nameBadgeDone]: 'notdone'
		});

	const setFirebaseDone = () =>
		docRef.update({
			[nameBadgeDone]: 'done'
		});

	const updateFirebase = isDone ? setFirebaseNotDone : setFirebaseDone;

	/// End Firebasestuff

	return (
		<Card>
			<CardActionArea>
				<AuthCheck
					fallback={
						<CardMedia
							onClick={props.notloggedinhandler}
							className={isDone ? classes.mediadone : classes.media}
							image={cloudinaryCore.url(`/badges/badge${props.badgeid}`, {
								width: 'auto',
								crop: 'scale',
								client_hints: 'true',
								sizes: '100vw',
								dpr: 'auto',
								quality: 'auto',
								fetch_format: 'auto'
							})}
							title={props.badgetext}
						/>
					}
				>
					<CardMedia
						onClick={() => {
							updateFirebase(props.badgeid);
							toggleIsDone();
						}}
						className={isDone ? classes.mediadone : classes.media}
						image={cloudinaryCore.url(`/badges/badge${props.badgeid}`, {
							width: 'auto',
							crop: 'scale',
							client_hints: 'true',
							sizes: '100vw',
							dpr: 'auto',
							quality: 'auto',
							fetch_format: 'auto'
						})}
						title={props.badgetext}
					/>
				</AuthCheck>
			</CardActionArea>

			<Tooltip arrow disableFocusListener disableTouchListener title={props.badgetext}>
				<Typography className={classes.text} align="center" variant="subtitle2" component="h2">
					{props.badgename}
				</Typography>
			</Tooltip>
		</Card>
	);
}
export default memo(BadgeCard);
