import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import cloudinary from 'cloudinary-core';

const cloudinaryCore = new cloudinary.Cloudinary({ cloud_name: 'zwifthub' });

const useStyles = makeStyles((theme) => ({
	media: {
		height: 0,
		paddingTop: '85.67%'
	},
	card: {
		margin: 'auto',
		maxWidth: '300px'
	},
	media2: {
		height: 0,
		paddingTop: '117.57%'
	}
}));

export default function Help(props) {
	const classes = useStyles();

	const descriptionElementRef = React.useRef(null);
	React.useEffect(
		() => {
			if (props.openHelp) {
				const { current: descriptionElement } = descriptionElementRef;
				if (descriptionElement !== null) {
					descriptionElement.focus();
				}
			}
		},
		[ props.openHelp ]
	);

	return (
		<div>
			<Dialog
				open={props.openHelp}
				onClose={props.handleClickCloseHelp}
				maxWidth="md"
				scroll="paper"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle align="center" id="scroll-dialog-title">
					Get started
				</DialogTitle>
				<DialogContent dividers={true}>
					<Typography
						style={{ color: 'rgba(0, 0, 0, 0.54)' }}
						component={'span'}
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
						align="center"
					>
						{' '}
						<p>
							Zwift has no third party API access at the moment. That's why as of now{' '}
							<u> only manual tracking of achievements/badges </u> is possible.{' '}
						</p>
						<br />
						<p>
							<strong>Introduction to ZwiftHub</strong>
						</p>
						<p>
							A video is sometimes easier to understand than reading a long how-to. ZwiftHub explained by
							Shane Miller (GPLama):
							<br />
							<a href="https://youtu.be/KgSfajJzW6M" target="_blank" rel="noopener noreferrer">
								click here to see the YouTube video
							</a>
						</p>
						<br />
						<p>
							<strong>Achievement- / Badge-Tracking</strong>
						</p>
						<p>
							In contrast to Zwift and with a (in my opinion) clearer distinction in my in mind, I
							separated badges and achievements. My definition of the two and the way to mark them done:
						</p>
						<p>
							<u>Achievements:</u>
						</p>
						<p>
							Rewards for completed routes --> Click on the trophy button at the left bottom of the route
							card to toggle your route achievement on (gold) and off (grey).{' '}
						</p>
						<p>
							<u>Badges:</u>
						</p>{' '}
						<p>
							Rewards for non-route specific goals --> In the badge-section click on the specific badge
							picture to toggle your badges on (color) and off (greyscale).
						</p>
						<br />
						<p>
							<strong>Display Settings</strong>
						</p>{' '}
					</Typography>

					<Card className={classes.card}>
						<CardMedia className={classes.media2} image={cloudinaryCore.url('settings')} title="Settings" />
					</Card>
					<Typography
						style={{ color: 'rgba(0, 0, 0, 0.54)' }}
						component={'span'}
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
						align="center"
					>
						<p>
							Sign in and click on your avatar in the top bar to change the display settings between
							metric and imperial units, show/hide lead-ins and achievement XP.
						</p>
						<br />
						<p>
							<strong>Route card key</strong>
						</p>{' '}
					</Typography>

					<Card className={classes.card}>
						<CardMedia
							className={classes.media}
							image={cloudinaryCore.url('routecardkey')}
							title="Routecard Key"
						/>
					</Card>
					<Typography
						style={{ color: 'rgba(0, 0, 0, 0.54)' }}
						component={'span'}
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
						align="center"
					>
						<p>
							<strong>A:</strong> <br /> World (abbreviated), route name, distance + lead-in (blue),
							elevation + lead-in (blue)
						</p>
						<p>
							<strong>B: </strong> <br />Elevation chart and segments <br />
							<br />Segment keys: <br />
							Red circle: 1,2,3,4 & HC(Hors catégorie) = Climb categories<br />
							Green circle: S = Sprint<br />
							Orange circle: L = Lap Jersey<br />
						</p>
						<p>
							<strong>C:</strong> <br />Trophy button to toggle achievements on and off + Achievement XP
						</p>{' '}
						<p>
							<strong>D: </strong>
							<br /> Number of KOMs, Sprints and Lap Jerseys
						</p>
						<p>
							<strong>E: </strong> <br />Expand button to see the map
						</p>
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={props.handleClickCloseHelp} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
