import React, { useContext, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { WorldFilterContext } from "../../contexts/WorldFilter.context";
import useToggle from "../../hooks/useToggleState";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  komchips: {
    padding: "0px 5px 5px",
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(0.3),
    minWidth: "120px",
    maxWidth: "120px",
  },
}));

function KomScotlandFilter() {
  const classes = useStyles();

  // Import from context
  const { toggleKom, chosenkom } = useContext(WorldFilterContext);

  // Definition of the toggle states for the Koms

  const [isSgurrNorth, toggleIsSgurrNorth] = useToggle(
    chosenkom.includes("Sgurr Summit North")
  );
  const [isSgurrSouth, toggleIsSgurrSouth] = useToggle(
    chosenkom.includes("Sgurr Summit South")
  );
  const [isBreakawayBrae, toggleIsBreakawayBrae] = useToggle(
    chosenkom.includes("Breakaway Brae")
  );
  const [isBreakawayBraeRev, toggleIsBreakawayBraeRev] = useToggle(
    chosenkom.includes("Breakaway Brae Rev.")
  );
  const [isClydeKicker, toggleIsClydeKicker] = useToggle(
    chosenkom.includes("The Clyde Kicker")
  );
  const [isClydeKickerRev, toggleIsClydeKickerRev] = useToggle(
    chosenkom.includes("The Clyde Kicker Rev.")
  );

  return (
    <div className={classes.root}>
      <div className={classes.komchips}>
        <Chip
          className={classes.chip}
          label="Sgurr Summit North"
          onClick={() => {
            toggleKom("Sgurr Summit North");
            toggleIsSgurrNorth();
          }}
          variant={isSgurrNorth ? "default" : "outlined"}
          color={isSgurrNorth ? "primary" : "default"}
          style={{ color: isSgurrNorth ? "" : "#b4b4b4" }}
          size="small"
        />
        <Chip
          className={classes.chip}
          label="Sgurr Summit South" //abbreviated so it fits on the button
          onClick={() => {
            toggleKom("Sgurr Summit South");
            toggleIsSgurrSouth();
          }}
          variant={isSgurrSouth ? "default" : "outlined"}
          color={isSgurrSouth ? "primary" : "default"}
          style={{ color: isSgurrSouth ? "" : "#b4b4b4" }}
          size="small"
        />
        <Chip
          className={classes.chip}
          label="Breakaway Brae"
          onClick={() => {
            toggleKom("Breakaway Brae");
            toggleIsBreakawayBrae();
          }}
          variant={isBreakawayBrae ? "default" : "outlined"}
          color={isBreakawayBrae ? "primary" : "default"}
          style={{ color: isBreakawayBrae ? "" : "#b4b4b4" }}
          size="small"
        />
        <Chip
          className={classes.chip}
          label="Breakaway Brae Rev."
          onClick={() => {
            toggleKom("Breakaway Brae Rev.");
            toggleIsBreakawayBraeRev();
          }}
          variant={isBreakawayBraeRev ? "default" : "outlined"}
          color={isBreakawayBraeRev ? "primary" : "default"}
          style={{ color: isBreakawayBraeRev ? "" : "#b4b4b4" }}
          size="small"
        />
        <Chip
          className={classes.chip}
          label="The Clyde Kicker"
          onClick={() => {
            toggleKom("The Clyde Kicker");
            toggleIsClydeKicker();
          }}
          variant={isClydeKicker ? "default" : "outlined"}
          color={isClydeKicker ? "primary" : "default"}
          style={{ color: isClydeKicker ? "" : "#b4b4b4" }}
          size="small"
        />
        <Chip
          className={classes.chip}
          label="The Clyde Kicker Rev."
          onClick={() => {
            toggleKom("The Clyde Kicker Rev.");
            toggleIsClydeKickerRev();
          }}
          variant={isClydeKickerRev ? "default" : "outlined"}
          color={isClydeKickerRev ? "primary" : "default"}
          style={{ color: isClydeKickerRev ? "" : "#b4b4b4" }}
          size="small"
        />
      </div>
    </div>
  );
}
export default memo(KomScotlandFilter);

