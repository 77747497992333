import React, { useState, memo, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LapWatopiaFilter from './LapWatopiaFilter';
import LapLondonFilter from './LapLondonFilter';
import LapNewYorkFilter from './LapNewYorkFilter';
import LapInnsbruckFilter from './LapInnsbruckFilter';
import LapYorkshireFilter from './LapYorkshireFilter';
import LapRichmondFilter from './LapRichmondFilter';
import LapFranceFilter from './LapFranceFilter';
import LapParisFilter from './LapParisFilter';
import LapMakuriFilter from './LapMakuriFilter';
import LapScotlandFilter from './LapScotlandFilter';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper
	},
	nested: {
		paddingLeft: theme.spacing(4)
	},
	LapHeaders: {
		color: '#fe0100'
	},
	container: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap'
	}
}));

function NavLapList() {
	const classes = useStyles();

	// Import from context
	const { setchosenlap } = useContext(WorldFilterContext);

	// Lap subheaders accordeon action
	const [ activeLap, setActiveLap ] = useState(true);

	const handleClickLapWatopia = () => {
		setActiveLap(activeLap === 'LapWatopia' ? true : 'LapWatopia');
	};
	const handleClickLapLondon = () => {
		setActiveLap(activeLap === 'LapLondon' ? true : 'LapLondon');
	};
	const handleClickLapNewYork = () => {
		setActiveLap(activeLap === 'LapNewYork' ? true : 'LapNewYork');
	};
	const handleClickLapInnsbruck = () => {
		setActiveLap(activeLap === 'LapInnsbruck' ? true : 'LapInnsbruck');
	};
	const handleClickLapYorkshire = () => {
		setActiveLap(activeLap === 'LapYorkshire' ? true : 'LapYorkshire');
	};
	const handleClickLapRichmond = () => {
		setActiveLap(activeLap === 'LapRichmond' ? true : 'LapRichmond');
	};
	const handleClickLapFrance = () => {
		setActiveLap(activeLap === 'LapFrance' ? true : 'LapFrance');
	};
	const handleClickLapParis = () => {
		setActiveLap(activeLap === 'LapParis' ? true : 'LapParis');
	};
	const handleClickLapMakuri = () => {
		setActiveLap(activeLap === 'LapMakuri' ? true : 'LapMakuri');
	};
	const handleClickLapScotland = () => {
		setActiveLap(activeLap === 'LapScotland' ? true : 'LapScotland');
	};
	//

	return (
		<List component="div" disablePadding className={classes.container}>
			<Chip
				className={classes.chip}
				onClick={() => {
					setchosenlap([]);
					setActiveLap(false);
				}}
				size="small"
				label="reset all Lap Jerseys"
				variant="outlined"
				color="secondary"
			/>
			<ListItem button onClick={handleClickLapWatopia} className={classes.nested}>
				<ListItemText className={classes.LapHeaders} primary="Watopia" />
				{activeLap === 'LapWatopia' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeLap === 'LapWatopia'} timeout="auto" unmountOnExit>
				<LapWatopiaFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickLapLondon} className={classes.nested}>
				<ListItemText className={classes.LapHeaders} primary="London" />
				{activeLap === 'LapLondon' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeLap === 'LapLondon'} timeout="auto" unmountOnExit>
				<LapLondonFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickLapNewYork} className={classes.nested}>
				<ListItemText className={classes.LapHeaders} primary="New York" />
				{activeLap === 'LapNewYork' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeLap === 'LapNewYork'} timeout="auto" unmountOnExit>
				<LapNewYorkFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickLapInnsbruck} className={classes.nested}>
				<ListItemText className={classes.LapHeaders} primary="Innsbruck" />
				{activeLap === 'LapInnsbruck' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeLap === 'LapInnsbruck'} timeout="auto" unmountOnExit>
				<LapInnsbruckFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickLapYorkshire} className={classes.nested}>
				<ListItemText className={classes.LapHeaders} primary="Yorkshire" />
				{activeLap === 'LapYorkshire' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeLap === 'LapYorkshire'} timeout="auto" unmountOnExit>
				<LapYorkshireFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickLapRichmond} className={classes.nested}>
				<ListItemText className={classes.LapHeaders} primary="Richmond" />
				{activeLap === 'LapRichmond' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeLap === 'LapRichmond'} timeout="auto" unmountOnExit>
				<LapRichmondFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickLapFrance} className={classes.nested}>
				<ListItemText className={classes.LapHeaders} primary="France" />
				{activeLap === 'LapFrance' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeLap === 'LapFrance'} timeout="auto" unmountOnExit>
				<LapFranceFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickLapParis} className={classes.nested}>
				<ListItemText className={classes.LapHeaders} primary="Paris" />
				{activeLap === 'LapParis' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeLap === 'LapParis'} timeout="auto" unmountOnExit>
				<LapParisFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickLapMakuri} className={classes.nested}>
				<ListItemText className={classes.LapHeaders} primary="Makuri" />
				{activeLap === 'LapMakuri' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeLap === 'LapMakuri'} timeout="auto" unmountOnExit>
				<LapMakuriFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickLapScotland} className={classes.nested}>
				<ListItemText className={classes.LapHeaders} primary="Scotland" />
				{activeLap === 'LapScotland' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeLap === 'LapScotland'} timeout="auto" unmountOnExit>
				<LapScotlandFilter />
			</Collapse>
			<Divider />
		</List>
	);
}
export default memo(NavLapList);
