import React, { memo, useContext } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import { WorldFilterContext } from '../contexts/WorldFilter.context';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	title: {
		flexGrow: 1,
		display: 'none'
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25)
		},
		marginLeft: 0,
		width: '100%'
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	inputRoot: {
		color: 'inherit',
		width: '100%'
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`
		// transition: theme.transitions.create('width'),
		// width: '100%'
	}
}));

function SearchDrawer(props) {
	const classes = useStyles();

	const { searchword, setsearchword } = useContext(WorldFilterContext);

	const handleChange = (e) => {
		setsearchword(e.target.value);
	};

	const handleClearSearch = (e) => {
		setsearchword('');
	};

	const searchempty = searchword === '';

	return (
		<div className={classes.root}>
			<AppBar position="static">
				<Toolbar>
					<div className={classes.search}>
						<div className={classes.searchIcon}>
							<SearchIcon />
						</div>
						<InputBase
							placeholder="Search Route…"
							inputRef={(input) => input && input.focus()}
							type="text"
							classes={{
								root: classes.inputRoot,
								input: classes.inputInput
							}}
							onChange={handleChange}
							inputProps={{ 'aria-label': 'search' }}
							value={searchword}
							endAdornment={
								<InputAdornment position="end">
									{searchempty ? (
										<p />
									) : (
										<IconButton aria-label="cancel input" onMouseDown={handleClearSearch}>
											<CancelIcon fontSize="small" style={{ color: '#fff' }} />
										</IconButton>
									)}
								</InputAdornment>
							}
						/>
					</div>
				</Toolbar>
			</AppBar>
		</div>
	);
}

export default memo(SearchDrawer);
