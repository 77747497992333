import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

export default function ShutDown(props) {
	const descriptionElementRef = React.useRef(null);
	React.useEffect(
		() => {
			if (props.shutDown) {
				const { current: descriptionElement } = descriptionElementRef;
				if (descriptionElement !== null) {
					descriptionElement.focus();
				}
			}
		},
		[ props.shutDown ]
	);

	return (
		<div>
			<Dialog
				open={props.shutDown}
				onClose={props.handleShutDown}
				maxWidth="md"
				scroll="paper"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">ZwiftHub will shut down at the end of April</DialogTitle>
				<DialogContent dividers={true}>
					<Typography
						style={{ color: 'rgba(0, 0, 0, 0.54)' }}
						component={'span'}
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
					>
						<p>
							First of all: Thank you to all the amazing individuals in the indoor cycling community who
							supported ZwiftHub. Thanks for sharing the cost of hosting the web app with me and for your
							supportive messages that kept me motivated to keep working on the project.
						</p>{' '}
						<p>
							Exactly one year ago I published ZwiftHub and wrote <a
								href="https://www.reddit.com/r/Zwift/comments/fqhkf3/i_created_zwifthubcom_a_web_app_to_help_you_get/"
								target="_blank"
								rel="noopener noreferrer"
							>
								this post on Reddit
							</a>. What a journey it has been. ZwiftHub started as my first coding project in my endeavor
							to pursue a career change, combining my need for some practical software development
							experience and my passion for cycling.
						</p>{' '}
						<p>
							When I started this hobby project, I didn't dare to dream that one year later it has over
							2,000,000 page visits, 60,000 registered users, and 280,000 unique visitors from 166
							countries. My personal highlight was the recognition by two cycling media outlets I've been
							a fan of for a long time:{' '}
							<a
								href="https://www.youtube.com/watch?v=KgSfajJzW6M"
								target="_blank"
								rel="noopener noreferrer"
							>
								GPLama
							</a>{' '}
							and{' '}
							<a
								href="https://cyclingtips.com/2021/01/zwifthub-gives-you-the-features-zwift-really-should/"
								target="_blank"
								rel="noopener noreferrer"
							>
								CyclingTips
							</a>{' '}
							.
						</p>{' '}
						<p>
							So why do I shut down ZwiftHub? Whilst I celebrated the success of the web app, the
							increasing user number meant that what started as a fun project ended up in a lot of unpaid
							work and higher costs to cover due to more traffic. Answering support emails, catching up
							with Zwift's latest changes, fixing bugs, maintaining the backend, etc. This took up a lot
							of my free time - time I don't have anymore because...{' '}
						</p>{' '}
						<p>
							I have a new job! As I mentioned in the beginning ZwiftHub was my starting point to a career
							change to software development. It worked out. And, dream come true, I am able to stay
							within the indoor cycling realm. I'm working now for the company whose Kickr kicked off my
							indoor cycling career. I will keep working on enhancing your indoor cycling experience as a
							software developer at<strong>
								{' '}
								<a href="https://www.wahoofitness.com/" target="_blank" rel="noopener noreferrer">
									Wahoo Fitness
								</a>{' '}
								-{' '}
								<a href="https://thesufferfest.com/" target="_blank" rel="noopener noreferrer">
									The Sufferfest
								</a>.{' '}
							</strong>
						</p>{' '}
						<p>
							Although Wahoo would allow me to keep my hobby project, this doesn't align with my values;
							it's not the right thing to do. As a longtime Wahooligan, I'm grateful for this amazing
							professional opportunity and want to focus on this challenge without distraction.
						</p>{' '}
						<p>
							{' '}
							Thanks again to everyone who was part of this journey. No matter if Zwift or<strong>
								{' '}
								<a href="https://thesufferfest.com/" target="_blank" rel="noopener noreferrer">
									The Sufferfest
								</a>
							</strong>, indoors or outdoors, fast or slow, uphill or downhill: I love cycling, you love
							cycling, spread the love, stay safe and healthy and I will see you out there!
						</p>{' '}
						<p> Toby</p>{' '}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={props.handleShutDown} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
