import React, { useContext, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { WorldFilterContext } from "../../contexts/WorldFilter.context";
import useToggle from "../../hooks/useToggleState";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  komchips: {
    padding: "0px 5px 5px",
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(0.3),
    minWidth: "120px",
    maxWidth: "120px",
  },
}));

function KomMakuriFilter() {
  const classes = useStyles();

  // Import from context
  const { toggleKom, chosenkom } = useContext(WorldFilterContext);

  // Definition of the toggle states for the Koms

  const [isTempleKom, toggleIsTempleKom] = useToggle(
    chosenkom.includes("Temple KOM")
  );
  const [isTempleKomRev, toggleIsTempleKomRev] = useToggle(
    chosenkom.includes("Temple KOM Rev.")
  );
  const [isCastleKom, toggleIsCastleKom] = useToggle(
    chosenkom.includes("Castle KOM")
  );
  const [isRooftopKom, toggleIsRooftopKom] = useToggle(
    chosenkom.includes("Rooftop KOM")
  );

  return (
    <div className={classes.root}>
      <div className={classes.komchips}>
        <Chip
          className={classes.chip}
          label="Temple KOM"
          onClick={() => {
            toggleKom("Temple KOM");
            toggleIsTempleKom();
          }}
          variant={isTempleKom ? "default" : "outlined"}
          color={isTempleKom ? "primary" : "default"}
          style={{ color: isTempleKom ? "" : "#b4b4b4" }}
          size="small"
        />
        <Chip
          className={classes.chip}
          label="Temp. KOM Rev." //abbreviated so it fits on the button
          onClick={() => {
            toggleKom("Temple KOM Rev.");
            toggleIsTempleKomRev();
          }}
          variant={isTempleKomRev ? "default" : "outlined"}
          color={isTempleKomRev ? "primary" : "default"}
          style={{ color: isTempleKomRev ? "" : "#b4b4b4" }}
          size="small"
        />
        <Chip
          className={classes.chip}
          label="Castle KOM"
          onClick={() => {
            toggleKom("Castle KOM");
            toggleIsCastleKom();
          }}
          variant={isCastleKom ? "default" : "outlined"}
          color={isCastleKom ? "primary" : "default"}
          style={{ color: isCastleKom ? "" : "#b4b4b4" }}
          size="small"
        />
        <Chip
          className={classes.chip}
          label="Rooftop KOM"
          onClick={() => {
            toggleKom("Rooftop KOM");
            toggleIsRooftopKom();
          }}
          variant={isRooftopKom ? "default" : "outlined"}
          color={isRooftopKom ? "primary" : "default"}
          style={{ color: isRooftopKom ? "" : "#b4b4b4" }}
          size="small"
        />
      </div>
    </div>
  );
}
export default memo(KomMakuriFilter);

