import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	komchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function KomFranceFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleKom, chosenkom } = useContext(WorldFilterContext);

	// Definition of the toggle states for the Koms

	const [ isAqueducKom, toggleIsAqueducKom ] = useToggle(chosenkom.includes('Aqueduc KOM'));
	const [ isAqueducKomRev, toggleIsAqueducKomRev ] = useToggle(chosenkom.includes('Aqueduc KOM Rev.'));
	const [ isPetitKom, toggleIsPetitKom ] = useToggle(chosenkom.includes('Petit KOM'));
	const [ isVentouxKom, toggleIsVentouxKom ] = useToggle(chosenkom.includes('Ventoux KOM'));

	return (
		<div className={classes.root}>
			<div className={classes.komchips}>
				<Chip
					className={classes.chip}
					label="Aqueduc KOM"
					onClick={() => {
						toggleKom('Aqueduc KOM');
						toggleIsAqueducKom();
					}}
					variant={isAqueducKom ? 'default' : 'outlined'}
					color={isAqueducKom ? 'primary' : 'default'}
					style={{ color: isAqueducKom ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Aqueduc Rev."
					onClick={() => {
						toggleKom('Aqueduc KOM Rev.');
						toggleIsAqueducKomRev();
					}}
					variant={isAqueducKomRev ? 'default' : 'outlined'}
					color={isAqueducKomRev ? 'primary' : 'default'}
					style={{ color: isAqueducKomRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Petit KOM"
					onClick={() => {
						toggleKom('Petit KOM');
						toggleIsPetitKom();
					}}
					variant={isPetitKom ? 'default' : 'outlined'}
					color={isPetitKom ? 'primary' : 'default'}
					style={{ color: isPetitKom ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Ventoux KOM"
					onClick={() => {
						toggleKom('Ventoux KOM');
						toggleIsVentouxKom();
					}}
					variant={isVentouxKom ? 'default' : 'outlined'}
					color={isVentouxKom ? 'primary' : 'default'}
					style={{ color: isVentouxKom ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(KomFranceFilter);
