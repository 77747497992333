import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	sprintchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function SprintMakuriFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleSprint, chosensprint } = useContext(WorldFilterContext);

	// Definition of the toggle states for the sprints

	const [ isAlleySprint, toggleIsAlleySprint ] = useToggle(chosensprint.includes('Alley Sprint'));
	const [ isAlleySprintRev, toggleIsAlleySprintRev ] = useToggle(chosensprint.includes('Alley Sprint Rev.'));
	const [ isBoardwalkSprint, toggleIsBoardwalkSprint ] = useToggle(chosensprint.includes('Boardwalk Sprint'));
	const [ isBoardwalkSprintRev, toggleIsBoardwalkSprintRev ] = useToggle(chosensprint.includes('Boardwalk Sprint Rev.'));
	const [ isCastleSprint, toggleIsCastleSprint ] = useToggle(chosensprint.includes('Castle Sprint'));
	const [ isCastleSprintRev, toggleIsCastleSprintRev ] = useToggle(chosensprint.includes('Castle Sprint Rev.'));
	const [ isCountrySprint, toggleIsCountrySprint ] = useToggle(chosensprint.includes('Country Sprint'));
	const [ isCountrySprintRev, toggleIsCountrySprintRev ] = useToggle(chosensprint.includes('Country Sprint Rev.'));
	const [ isRailwaySprint, toggleIsRailwaySprint ] = useToggle(chosensprint.includes('Railway Sprint'));
	const [ isShisaSprint, toggleIsShisaSprint ] = useToggle(chosensprint.includes('Shisa Sprint'));
	const [ isShisaSprintRev, toggleIsShisaSprintRev ] = useToggle(chosensprint.includes('Shisa Sprint Rev.'));
	const [ isTidepoolSprint, toggleIsTidepoolSprint ] = useToggle(chosensprint.includes('Tidepool Sprint'));
	const [ isTidepoolSprintRev, toggleIsTidepoolSprintRev ] = useToggle(chosensprint.includes('Tidepool Sprint Rev.'));
	const [ isTowerSprint, toggleIsTowerSprint ] = useToggle(chosensprint.includes('Tower Sprint'));
	const [ isTowerSprintRev, toggleIsTowerSprintRev ] = useToggle(chosensprint.includes('Tower Sprint Rev.'));
	const [ isVillageSprint, toggleIsVillageSprint ] = useToggle(chosensprint.includes('Village Sprint'));
	const [ isVillageSprintRev, toggleIsVillageSprintRev ] = useToggle(chosensprint.includes('Village Sprint Rev.'));


	return (
		<div className={classes.root}>
			<div className={classes.sprintchips}>
				<Chip
					className={classes.chip}
					label="Alley Sprint"
					onClick={() => {
						toggleSprint('Alley Sprint');
						toggleIsAlleySprint();
					}}
					variant={isAlleySprint ? 'default' : 'outlined'}
					color={isAlleySprint ? 'primary' : 'default'}
					style={{ color: isAlleySprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Alley Sprint Rev."
					onClick={() => {
						toggleSprint('Alley Sprint Rev.');
						toggleIsAlleySprintRev();
					}}
					variant={isAlleySprintRev ? 'default' : 'outlined'}
					color={isAlleySprintRev ? 'primary' : 'default'}
					style={{ color: isAlleySprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Boardwalk Sprint"
					onClick={() => {
						toggleSprint('Boardwalk Sprint');
						toggleIsBoardwalkSprint();
					}}
					variant={isBoardwalkSprint ? 'default' : 'outlined'}
					color={isBoardwalkSprint ? 'primary' : 'default'}
					style={{ color: isBoardwalkSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Boardwalk Sprint Rev."
					onClick={() => {
						toggleSprint('Boardwalk Sprint Rev.');
						toggleIsBoardwalkSprintRev();
					}}
					variant={isBoardwalkSprintRev ? 'default' : 'outlined'}
					color={isBoardwalkSprintRev ? 'primary' : 'default'}
					style={{ color: isBoardwalkSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Castle Sprint"
					onClick={() => {
						toggleSprint('Castle Sprint');
						toggleIsCastleSprint();
					}}
					variant={isCastleSprint ? 'default' : 'outlined'}
					color={isCastleSprint ? 'primary' : 'default'}
					style={{ color: isCastleSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Castle Sprint Rev."
					onClick={() => {
						toggleSprint('Castle Sprint Rev.');
						toggleIsCastleSprintRev();
					}}
					variant={isCastleSprintRev ? 'default' : 'outlined'}
					color={isCastleSprintRev ? 'primary' : 'default'}
					style={{ color: isCastleSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Country Sprint"
					onClick={() => {
						toggleSprint('Country Sprint');
						toggleIsCountrySprint();
					}}
					variant={isCountrySprint ? 'default' : 'outlined'}
					color={isCountrySprint ? 'primary' : 'default'}
					style={{ color: isCountrySprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Country Spr. Rev."
					onClick={() => {
						toggleSprint('Country Sprint Rev.');
						toggleIsCountrySprintRev();
					}}
					variant={isCountrySprintRev ? 'default' : 'outlined'}
					color={isCountrySprintRev ? 'primary' : 'default'}
					style={{ color: isCountrySprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Railway Sprint"
					onClick={() => {
						toggleSprint('Railway Sprint');
						toggleIsRailwaySprint();
					}}
					variant={isRailwaySprint ? 'default' : 'outlined'}
					color={isRailwaySprint ? 'primary' : 'default'}
					style={{ color: isRailwaySprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Shisa Sprint"
					onClick={() => {
						toggleSprint('Shisa Sprint');
						toggleIsShisaSprint();
					}}
					variant={isShisaSprint ? 'default' : 'outlined'}
					color={isShisaSprint ? 'primary' : 'default'}
					style={{ color: isShisaSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Shisa Sprint Rev."
					onClick={() => {
						toggleSprint('Shisa Sprint Rev.');
						toggleIsShisaSprintRev();
					}}
					variant={isShisaSprintRev ? 'default' : 'outlined'}
					color={isShisaSprintRev ? 'primary' : 'default'}
					style={{ color: isShisaSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Tidepool Sprint"
					onClick={() => {
						toggleSprint('Tidepool Sprint');
						toggleIsTidepoolSprint();
					}}
					variant={isTidepoolSprint ? 'default' : 'outlined'}
					color={isTidepoolSprint ? 'primary' : 'default'}
					style={{ color: isTidepoolSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Tidepool Sprint Rev."
					onClick={() => {
						toggleSprint('Tidepool Sprint Rev.');
						toggleIsTidepoolSprintRev();
					}}
					variant={isTidepoolSprintRev ? 'default' : 'outlined'}
					color={isTidepoolSprintRev ? 'primary' : 'default'}
					style={{ color: isTidepoolSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Tower Sprint"
					onClick={() => {
						toggleSprint('Tower Sprint');
						toggleIsTowerSprint();
					}}
					variant={isTowerSprint ? 'default' : 'outlined'}
					color={isTowerSprint ? 'primary' : 'default'}
					style={{ color: isTowerSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Tower Sprint Rev."
					onClick={() => {
						toggleSprint('Tower Sprint Rev.');
						toggleIsTowerSprintRev();
					}}
					variant={isTowerSprintRev ? 'default' : 'outlined'}
					color={isTowerSprintRev ? 'primary' : 'default'}
					style={{ color: isTowerSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Village Sprint"
					onClick={() => {
						toggleSprint('Village Sprint');
						toggleIsVillageSprint();
					}}
					variant={isVillageSprint ? 'default' : 'outlined'}
					color={isVillageSprint ? 'primary' : 'default'}
					style={{ color: isVillageSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Village Sprint Rev."
					onClick={() => {
						toggleSprint('Village Sprint Rev.');
						toggleIsVillageSprintRev();
					}}
					variant={isVillageSprintRev ? 'default' : 'outlined'}
					color={isVillageSprintRev ? 'primary' : 'default'}
					style={{ color: isVillageSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(SprintMakuriFilter);
