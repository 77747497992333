import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	sprintchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function SprintNewYorkFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleSprint, chosensprint } = useContext(WorldFilterContext);

	// Definition of the toggle states for the sprints

	const [ isNewYorkSprint, toggleIsNewYorkSprint ] = useToggle(chosensprint.includes('New York Sprint'));
	const [ isNewYorkSprintRev, toggleIsNewYorkSprintRev ] = useToggle(chosensprint.includes('New York Sprint Rev.'));

	return (
		<div className={classes.root}>
			<div className={classes.sprintchips}>
				<Chip
					className={classes.chip}
					label="New York Sprint"
					onClick={() => {
						toggleSprint('New York Sprint');
						toggleIsNewYorkSprint();
					}}
					variant={isNewYorkSprint ? 'default' : 'outlined'}
					color={isNewYorkSprint ? 'primary' : 'default'}
					style={{ color: isNewYorkSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="NY Sprint Rev."
					onClick={() => {
						toggleSprint('New York Sprint Rev.');
						toggleIsNewYorkSprintRev();
					}}
					variant={isNewYorkSprintRev ? 'default' : 'outlined'}
					color={isNewYorkSprintRev ? 'primary' : 'default'}
					style={{ color: isNewYorkSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(SprintNewYorkFilter);
