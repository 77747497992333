import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	sprintchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function SprintWatopiaFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleSprint, chosensprint } = useContext(WorldFilterContext);

	// Definition of the toggle states for the sprints

	const [ isWatopiaSprint, toggleIsWatopiaSprint ] = useToggle(chosensprint.includes('Watopia Sprint'));
	const [ isWatopiaSprintRev, toggleIsWatopiaSprintRev ] = useToggle(chosensprint.includes('Watopia Rev'));
	const [ isFuegoFlats, toggleIsFuegoFlats ] = useToggle(chosensprint.includes('Fuego Flats'));
	const [ isStonewaySprint, toggleisStonewaySprint ] = useToggle(chosensprint.includes('Stoneway'));
	const [ isAcropolisSprint, toggleIsAcropolisSprint ] = useToggle(chosensprint.includes('Acropolis'));
	const [ isSasquatchSprint, toggleIsSasquatchSprint ] = useToggle(chosensprint.includes('Sasquatch'));
	const [ isWoodlandSprint, toggleIsWoodlandSprint ] = useToggle(chosensprint.includes('Woodland'));
	const [ isStonewaySprintRev, toggleIsStonewaySprintRev ] = useToggle(chosensprint.includes('Stoneway Rev'));
	const [ isAcropolisSprintRev, toggleIsAcropolisSprintRev ] = useToggle(chosensprint.includes('Acropolis Rev'));
	const [ isSasquatchSprintRev, toggleIsSasquatchSprintRev ] = useToggle(chosensprint.includes('Sasquatch Rev'));
	const [ isWoodlandSprintRev, toggleIsWoodlandSprintRev ] = useToggle(chosensprint.includes('Woodland Rev'));

	return (
		<div className={classes.root}>
			<div className={classes.sprintchips}>
				<Chip
					className={classes.chip}
					label="Watopia Sprint"
					onClick={() => {
						toggleSprint('Watopia Sprint');
						toggleIsWatopiaSprint();
					}}
					variant={isWatopiaSprint ? 'default' : 'outlined'}
					color={isWatopiaSprint ? 'primary' : 'default'}
					style={{ color: isWatopiaSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Watopia Rev"
					onClick={() => {
						toggleSprint('Watopia Rev');
						toggleIsWatopiaSprintRev();
					}}
					variant={isWatopiaSprintRev ? 'default' : 'outlined'}
					color={isWatopiaSprintRev ? 'primary' : 'default'}
					style={{ color: isWatopiaSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Stoneway"
					onClick={() => {
						toggleSprint('Stoneway');
						toggleisStonewaySprint();
					}}
					variant={isStonewaySprint ? 'default' : 'outlined'}
					color={isStonewaySprint ? 'primary' : 'default'}
					style={{ color: isStonewaySprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Stoneway Rev"
					onClick={() => {
						toggleSprint('Stoneway Rev');
						toggleIsStonewaySprintRev();
					}}
					variant={isStonewaySprintRev ? 'default' : 'outlined'}
					color={isStonewaySprintRev ? 'primary' : 'default'}
					style={{ color: isStonewaySprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Acropolis"
					onClick={() => {
						toggleSprint('Acropolis');
						toggleIsAcropolisSprint();
					}}
					variant={isAcropolisSprint ? 'default' : 'outlined'}
					color={isAcropolisSprint? 'primary' : 'default'}
					style={{ color: isAcropolisSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Acropolis Rev"
					onClick={() => {
						toggleSprint('Acropolis Rev');
						toggleIsAcropolisSprintRev();
					}}
					variant={isAcropolisSprintRev ? 'default' : 'outlined'}
					color={isAcropolisSprintRev ? 'primary' : 'default'}
					style={{ color: isAcropolisSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Sasquatch"
					onClick={() => {
						toggleSprint('Sasquatch');
						toggleIsSasquatchSprint();
					}}
					variant={isSasquatchSprint ? 'default' : 'outlined'}
					color={isSasquatchSprint ? 'primary' : 'default'}
					style={{ color: isSasquatchSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Sasquatch Rev"
					onClick={() => {
						toggleSprint('Sasquatch Rev');
						toggleIsSasquatchSprintRev();
					}}
					variant={isSasquatchSprintRev ? 'default' : 'outlined'}
					color={isSasquatchSprintRev ? 'primary' : 'default'}
					style={{ color: isSasquatchSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Woodland"
					onClick={() => {
						toggleSprint('Woodland');
						toggleIsWoodlandSprint();
					}}
					variant={isWoodlandSprint ? 'default' : 'outlined'}
					color={isWoodlandSprint ? 'primary' : 'default'}
					style={{ color: isWoodlandSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Woodland Rev"
					onClick={() => {
						toggleSprint('Woodland Rev');
						toggleIsWoodlandSprintRev();
					}}
					variant={isWoodlandSprintRev ? 'default' : 'outlined'}
					color={isWoodlandSprintRev ? 'primary' : 'default'}
					style={{ color: isWoodlandSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Fuego Flats"
					onClick={() => {
						toggleSprint('Fuego Flats');
						toggleIsFuegoFlats();
					}}
					variant={isFuegoFlats ? 'default' : 'outlined'}
					color={isFuegoFlats ? 'primary' : 'default'}
					style={{ color: isFuegoFlats ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(SprintWatopiaFilter);
