import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	sprintchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function SprintLondonFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleSprint, chosensprint } = useContext(WorldFilterContext);

	// Definition of the toggle states for the sprints

	const [ isTheMall, toggleIsTheMall ] = useToggle(chosensprint.includes('The Mall'));
	const [ isTheMallRev, toggleIsTheMallRev ] = useToggle(chosensprint.includes('The Mall Rev.'));

	return (
		<div className={classes.root}>
			<div className={classes.sprintchips}>
				<Chip
					className={classes.chip}
					label="The Mall"
					onClick={() => {
						toggleSprint('The Mall');
						toggleIsTheMall();
					}}
					variant={isTheMall ? 'default' : 'outlined'}
					color={isTheMall ? 'primary' : 'default'}
					style={{ color: isTheMall ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="The Mall Rev."
					onClick={() => {
						toggleSprint('The Mall Rev.');
						toggleIsTheMallRev();
					}}
					variant={isTheMallRev ? 'default' : 'outlined'}
					color={isTheMallRev ? 'primary' : 'default'}
					style={{ color: isTheMallRev ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(SprintLondonFilter);
