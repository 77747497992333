import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { WorldFilterContext } from '../contexts/WorldFilter.context';
import ProgressionIconToRun from './ProgressionIconToRun';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import { AuthCheck } from 'reactfire';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import LandscapeIcon from '@material-ui/icons/Landscape';
import StarIcon from '@material-ui/icons/Star';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

const useStyles = makeStyles((theme) => ({
	root: {
		width: 280,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	avatar: {
		width: 35,
		height: 35
	},
	card: {
		width: 250,
		marginTop: '1rem'
	},
	iconcontainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		alignItems: 'center'
	},
	justdemo: {
		padding: '15px 5px 5px'
	},
	cardheader: {
		padding: '8px 16px'
	},
	overviewcontainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '8px 16px 8px'
	},
	textoverview: {
		fontSize: '0.875rem'
	},
	textsubheader: {
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: '0.75rem'
	},
	headline: {
		display: 'flex',
		justifyContent: 'center',
		paddingBottom: '8px'
	},
	progressIcon: {
		position: 'absolute'
	}
}));

function AchievementDrawerRun() {
	const classes = useStyles();

	//import achievement list
	const { userachievements, initialRoutes, achievedrouteids, resultbadgesrunning, displaymiles } = useContext(
		WorldFilterContext
	);

	// partial of the different Worlds
	function inRange(x) {
		return this[0] <= x && x <= this[1];
	}
	//route ids
	const routeids = userachievements.map((el) => el.slice(8));

	//running route ids
	const routeidsRunning = routeids.filter((id) => id > 76 && id < 88);

	//achievedrouteidsRunning
	const achievedrouteidsRunning = achievedrouteids.filter((id) => id > 76 && id < 88);

	//runningroutes out of initials
	const initialRunningRoutes = initialRoutes.filter((route) => route.sport === 'running');

	// number of achievements filter by world
	const resultWa = routeids.filter(inRange, [ 77, 82 ]).length;
	const resultNe = routeids.filter(inRange, [ 83, 87 ]).length;

	// total achievement percentage
	const achievmentpercent = Math.round(routeidsRunning.length * 100 / 11);

	// percentages of Worlds
	const achievmentpercentWa = Math.round(resultWa * 100 / 6);
	const achievmentpercentNe = Math.round(resultNe * 100 / 5);

	// get sums out of initialRunningRoutes
	const totaldistance = Math.round(initialRunningRoutes.reduce((acc, route) => acc + route.distance, 0));
	const achieveddistance =
		achievedrouteidsRunning[0] === 0
			? 0
			: Math.round(
					achievedrouteidsRunning
						.map((routeid) => initialRoutes.find((x) => x.routeid === routeid).distance)
						.reduce((a, b) => a + b)
				);
	const totalelevation = Math.round(initialRunningRoutes.reduce((acc, route) => acc + route.elevation, 0));
	const achievedelevation =
		achievedrouteidsRunning[0] === 0
			? 0
			: Math.round(
					achievedrouteidsRunning
						.map((routeid) => initialRoutes.find((x) => x.routeid === routeid).elevation)
						.reduce((a, b) => a + b)
				);
	const totalexp = Math.round(initialRunningRoutes.reduce((acc, route) => acc + route.exp, 0));
	const achievedexp =
		achievedrouteidsRunning[0] === 0
			? 0
			: Math.round(
					achievedrouteidsRunning
						.map((routeid) => initialRoutes.find((x) => x.routeid === routeid).exp)
						.reduce((a, b) => a + b)
				);

	return (
		<div className={classes.root}>
			<Card className={classes.card}>
				<CardContent className={classes.headline}>
					<Typography variant="subtitle1" align="center">
						<strong>OVERALL PROGRESS</strong>
					</Typography>
				</CardContent>
				<CardContent className={classes.overviewcontainer}>
					<div>
						<Typography className={classes.textoverview} align="center">
							{achievmentpercent}%
						</Typography>
						<Avatar style={{ backgroundColor: 'transparent' }}>
							<CircularProgress
								className={classes.progressIcon}
								size={35}
								style={{ color: '#d3d3d3' }}
								variant="static"
								value={100}
							/>
							<CircularProgress
								className={classes.progressIcon}
								size={35}
								color="primary"
								variant="static"
								value={achievmentpercent}
							/>

							<EmojiEventsIcon className={classes.progressIcon} style={{ color: '#0093d1' }} />
						</Avatar>
						<Typography className={classes.textsubheader} align="center">
							#
						</Typography>
						<Typography className={classes.textsubheader} align="center">
							{routeidsRunning.length === 0 ? 0 : routeidsRunning.length}
						</Typography>
						<Divider />
						<Typography className={classes.textsubheader} align="center">
							11
						</Typography>
					</div>
					<div>
						<Typography className={classes.textoverview} align="center">
							{Math.round(achieveddistance / totaldistance * 100)}%
						</Typography>
						<Avatar style={{ backgroundColor: 'transparent' }}>
							<CircularProgress
								className={classes.progressIcon}
								size={35}
								style={{ color: '#d3d3d3' }}
								variant="static"
								value={100}
							/>
							<CircularProgress
								className={classes.progressIcon}
								size={35}
								color="primary"
								variant="static"
								value={Math.round(achieveddistance / totaldistance * 100)}
							/>

							<DirectionsRunIcon className={classes.progressIcon} style={{ color: '#0093d1' }} />
						</Avatar>
						<Typography className={classes.textsubheader} align="center">
							{displaymiles ? 'mi' : 'km'}
						</Typography>
						<Typography className={classes.textsubheader} align="center">
							{displaymiles ? Math.round(achieveddistance * 0.621371) : achieveddistance}
						</Typography>
						<Divider />
						<Typography className={classes.textsubheader} align="center">
							{displaymiles ? Math.round(totaldistance * 0.621371) : totaldistance}
						</Typography>
					</div>
					<div>
						<Typography className={classes.textoverview} align="center">
							{Math.round(achievedelevation / totalelevation * 100)}%
						</Typography>
						<Avatar style={{ backgroundColor: 'transparent' }}>
							<CircularProgress
								className={classes.progressIcon}
								size={35}
								style={{ color: '#d3d3d3' }}
								variant="static"
								value={100}
							/>
							<CircularProgress
								className={classes.progressIcon}
								size={35}
								color="primary"
								variant="static"
								value={Math.round(achievedelevation / totalelevation * 100)}
							/>

							<LandscapeIcon className={classes.progressIcon} style={{ color: '#0093d1' }} />
						</Avatar>
						<Typography className={classes.textsubheader} align="center">
							{displaymiles ? 'ft' : 'm'}
						</Typography>
						<Typography className={classes.textsubheader} align="center">
							{displaymiles ? Math.round(achievedelevation * 3.28084) : achievedelevation}
						</Typography>
						<Divider />
						<Typography className={classes.textsubheader} align="center">
							{displaymiles ? Math.round(totalelevation * 3.28084) : totalelevation}
						</Typography>
					</div>
					<div>
						<Typography className={classes.textoverview} align="center">
							{Math.round(achievedexp / totalexp * 100)}%
						</Typography>
						<Avatar className={classes.progressIcon} style={{ color: '#0093d1', backgroundColor: '#fff' }}>
							XP
						</Avatar>
						<Avatar style={{ backgroundColor: 'transparent' }}>
							<CircularProgress
								className={classes.progressIcon}
								size={35}
								style={{ color: '#d3d3d3' }}
								variant="static"
								value={100}
							/>
							<CircularProgress
								className={classes.progressIcon}
								size={35}
								color="primary"
								variant="static"
								value={Math.round(achievedexp / totalexp * 100)}
							/>
						</Avatar>
						<Typography className={classes.textsubheader} align="center">
							XP
						</Typography>
						<Typography className={classes.textsubheader} align="center">
							{achievedexp}
						</Typography>
						<Divider />
						<Typography className={classes.textsubheader} align="center">
							{totalexp}
						</Typography>
					</div>
					<div>
						<Typography className={classes.textoverview} align="center">
							{Math.round(resultbadgesrunning * 100 / 13)}%
						</Typography>
						<Avatar style={{ backgroundColor: 'transparent' }}>
							<CircularProgress
								className={classes.progressIcon}
								size={35}
								style={{ color: '#d3d3d3' }}
								variant="static"
								value={100}
							/>
							<CircularProgress
								className={classes.progressIcon}
								size={35}
								color="primary"
								variant="static"
								value={Math.round(resultbadgesrunning * 100 / 13)}
							/>

							<StarIcon className={classes.progressIcon} style={{ color: '#0093d1' }} />
						</Avatar>
						<Typography className={classes.textsubheader} align="center">
							#
						</Typography>
						<Typography className={classes.textsubheader} align="center">
							{resultbadgesrunning}
						</Typography>
						<Divider />
						<Typography className={classes.textsubheader} align="center">
							13
						</Typography>
					</div>
				</CardContent>
			</Card>

			<Card className={classes.card}>
				<CardContent className={classes.headline}>
					<Typography variant="subtitle1" align="center">
						<strong>ROUTE ACHIEVEMENTS</strong>
					</Typography>
				</CardContent>
				<CardHeader
					className={classes.cardheader}
					avatar={
						<div className={classes.iconcontainer}>
							<Avatar style={{ backgroundColor: '#fc6719', fontSize: '17px' }} className={classes.avatar}>
								Wa
							</Avatar>
							<ProgressionIconToRun achievmentpercent={achievmentpercentWa} />
						</div>
					}
					title="Watopia"
					subheader={`${achievmentpercentWa}% (${resultWa}/6)`}
				/>

				<CardHeader
					className={classes.cardheader}
					avatar={
						<div className={classes.iconcontainer}>
							<Avatar style={{ backgroundColor: '#e62a8b', fontSize: '17px' }} className={classes.avatar}>
								NY
							</Avatar>
							<ProgressionIconToRun achievmentpercent={achievmentpercentNe} />
						</div>
					}
					title="New York"
					subheader={`${achievmentpercentNe}% (${resultNe}/5)`}
				/>
			</Card>
			<AuthCheck
				fallback={
					<Typography align="center" color="primary" className={classes.justdemo}>
						This is just an demo of the achievement tracking. Sign in to track your own achievements.
					</Typography>
				}
			>
				{' '}
			</AuthCheck>
		</div>
	);
}

export default memo(AchievementDrawerRun);
