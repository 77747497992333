import React, { useState, useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PublicIcon from '@material-ui/icons/Public';
import WorldFilter from './WorldFilter';
import DistanceFilter from './DistanceFilter';
import ElevationFilter from './ElevationFilter';
import NavKomList from './KomFilter/NavKomList';
import NavSprintList from './SprintFilter/NavSprintList';
import NavLapList from './LapFilter/NavLapList';
import NavAchievement from './NavAchievement';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import LandscapeIcon from '@material-ui/icons/Landscape';
import GrainIcon from '@material-ui/icons/Grain';
import FlagIcon from '@material-ui/icons/Flag';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import SortIcon from '@material-ui/icons/Sort';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { WorldFilterContext } from '../contexts/WorldFilter.context';
import Button from '@material-ui/core/Button';
import AdSense from 'react-adsense';
import Switch from '@material-ui/core/Switch';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
// import Typography from '@material-ui/core/Typography';
// import cloudinary from 'cloudinary-core';
// import CardMedia from '@material-ui/core/CardMedia';

// const cloudinaryCore = new cloudinary.Cloudinary({ cloud_name: 'zwifthub' });

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper
	},
	nested: {
		paddingLeft: theme.spacing(4)
	},
	add: {
		padding: '0px 9px 0px',
		margin: 'auto'
	},
	divideradd: {
		margin: '0px 0px 10px'
	},
	sportselection: {
		paddingLeft: '70px'
	},
	donatebutton: {
		margin: '8px 36px 20px'
	},
	media: {
		margin: 'auto',
		maxWidth: '240px',
		height: 0,
		paddingTop: '60%'
	},
	supporttext: {
		marginBottom: '4px'
	}
}));

//openinghandlers for Listitems

function NestedList(props) {
	const classes = useStyles();
	//context import
	const {
		alphaUp,
		alphaDown,
		distUp,
		distDown,
		elevUp,
		elevDown,
		gradUp,
		gradDown,
		setchosenworld,
		setchosendistance,
		setchosenelevation,
		setchosenkom,
		setchosensprint,
		setchosenlap,
		setfilterachievments,
		setactiveachievementbutton,
		chosensport,
		setchosensport,
		docRef,
		user
	} = useContext(WorldFilterContext);

	const [ openWorld, setOpenWorld ] = useState(false);
	const [ openDistance, setOpenDistance ] = useState(false);
	const [ openElevation, setOpenElevation ] = useState(false);
	const [ openKom, setOpenKom ] = useState(false);
	const [ openSprint, setOpenSprint ] = useState(false);
	const [ openLap, setOpenLap ] = useState(false);
	const [ openAchievment, setOpenAchievment ] = useState(false);
	const [ openSort, setOpenSort ] = useState(false);

	const handleClickWorld = () => {
		setOpenWorld(!openWorld);
	};
	const handleClickDistance = () => {
		setOpenDistance(!openDistance);
	};
	const handleClickElevation = () => {
		setOpenElevation(!openElevation);
	};
	const handleClickKom = () => {
		setOpenKom(!openKom);
	};
	const handleClickSprint = () => {
		setOpenSprint(!openSprint);
	};
	const handleClickLap = () => {
		setOpenLap(!openLap);
	};
	const handleClickAchievment = () => {
		setOpenAchievment(!openAchievment);
	};
	const handleClickSort = () => {
		setOpenSort(!openSort);
	};
	const handleRestAll = () => {
		setOpenWorld(false);
		setOpenDistance(false);
		setOpenElevation(false);
		setOpenKom(false);
		setOpenSprint(false);
		setOpenLap(false);
		setOpenAchievment(false);
		setchosenworld([ 'Watopia', 'London', 'New York', 'Innsbruck', 'Yorkshire', 'Richmond', 'France', 'Paris','Makuri Islands' ]);
		setchosendistance([ 0, 174 ]);
		setchosenelevation([ 0, 2623 ]);
		setchosenkom([]);
		setchosensprint([]);
		setchosenlap([]);
		setfilterachievments([]);
		setactiveachievementbutton('all');
	};

	// handlesports
	const handleswitchsport = (event) => {
		chosensport === 'cycling' ? setchosensport('running') : setchosensport('cycling');
	};

	//firebase sport selection

	const switchtocycling = () =>
		docRef.update({
			sportselection: 'cycling'
		});

	const switchtorunning = () =>
		docRef.update({
			sportselection: 'running'
		});

	const handleswitchsportsfirebase = (event) => {
		chosensport === 'cycling' ? switchtorunning() : switchtocycling();
		chosensport === 'cycling' ? setchosensport('running') : setchosensport('cycling');
	};

	//

	return (
		<List component="nav" className={classes.root}>
			{user === null ? (
				<ListItem className={classes.sportselection}>
					<DirectionsBikeIcon fontSize="large" style={{ color: '#fc6719' }} />
					<Switch onChange={handleswitchsport} />
					<DirectionsRunIcon fontSize="large" style={{ color: '#0093d1' }} />
				</ListItem>
			) : (
				<ListItem className={classes.sportselection}>
					<DirectionsBikeIcon fontSize="large" style={{ color: '#fc6719' }} />
					<Switch checked={chosensport === 'running'} onChange={handleswitchsportsfirebase} />
					<DirectionsRunIcon fontSize="large" style={{ color: '#0093d1' }} />
				</ListItem>
			)}

			<Divider />

			<ListSubheader component="div" id="nested-list-subheader">
				Filters
				{/* <Button size="small" color="primary" onClick={handleRestAll}>
					[todo today]
				</Button> */}
				<Button size="small" color="secondary" onClick={handleRestAll}>
					[reset all]
				</Button>
			</ListSubheader>
			<ListItem button onClick={handleClickWorld}>
				<ListItemIcon>
					<PublicIcon />
				</ListItemIcon>
				<ListItemText primary="Worlds" />
				{openWorld ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={openWorld} timeout="auto" unmountOnExit>
				<WorldFilter />
			</Collapse>

			<ListItem button onClick={handleClickDistance}>
				<ListItemIcon>
					{chosensport === 'cycling' ? <DirectionsBikeIcon /> : <DirectionsRunIcon />}
				</ListItemIcon>
				<ListItemText primary="Distance" />
				{openDistance ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={openDistance} timeout="auto" unmountOnExit>
				<DistanceFilter />
			</Collapse>

			<ListItem button onClick={handleClickElevation}>
				<ListItemIcon>
					<LandscapeIcon />
				</ListItemIcon>
				<ListItemText primary="Elevation" />
				{openElevation ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={openElevation} timeout="auto" unmountOnExit>
				<ElevationFilter />
			</Collapse>

			{chosensport === 'cycling' ? (
				<div>
					<ListItem button onClick={handleClickKom}>
						<ListItemIcon>
							<GrainIcon style={{ color: '#fe0100' }} />
						</ListItemIcon>
						<ListItemText primary="KOMs" />
						{openKom ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={openKom} timeout="auto" unmountOnExit>
						<NavKomList />
					</Collapse>

					<ListItem button onClick={handleClickSprint}>
						<ListItemIcon>
							<FlagIcon style={{ color: '#56a91d' }} />
						</ListItemIcon>
						<ListItemText primary="Sprints" />
						{openSprint ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={openSprint} timeout="auto" unmountOnExit>
						<NavSprintList />
					</Collapse>

					<ListItem button onClick={handleClickLap}>
						<ListItemIcon>
							<AllInclusiveIcon color="primary" />
						</ListItemIcon>
						<ListItemText primary="Lap Jerseys" />
						{openLap ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={openLap} timeout="auto" unmountOnExit>
						<NavLapList />
					</Collapse>
				</div>
			) : (
				<div />
			)}

			<ListItem button onClick={handleClickAchievment}>
				<ListItemIcon>
					<EmojiEventsIcon style={{ color: '#FFD700' }} />
				</ListItemIcon>
				<ListItemText primary="Achievements" />
				{openAchievment ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={openAchievment} timeout="auto" unmountOnExit>
				<NavAchievement />
			</Collapse>

			<Divider />
			<ListSubheader component="div" id="nested-list-subheader">
				Sort options
			</ListSubheader>
			<ListItem button onClick={handleClickSort}>
				<ListItemIcon>
					<SortIcon />
				</ListItemIcon>
				<ListItemText primary="Sort by" />
				{openSort ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={openSort} timeout="auto" unmountOnExit>
				<List component="div" disablePadding dense={true}>
					<ListItem
						button
						onClick={() => {
							alphaUp();
						}}
						className={classes.nested}
					>
						<ListItemIcon>
							<SortByAlphaIcon />
						</ListItemIcon>
						<ListItemText primary="Route Names (A-Z)" />
					</ListItem>
					<ListItem
						button
						onClick={() => {
							alphaDown();
						}}
						className={classes.nested}
					>
						<ListItemIcon>
							<SortByAlphaIcon />
						</ListItemIcon>
						<ListItemText primary="Route Names (Z-A)" />
					</ListItem>
					<ListItem
						button
						onClick={() => {
							distUp();
						}}
						className={classes.nested}
					>
						<ListItemIcon>
							<SwapHorizIcon />
						</ListItemIcon>
						<ListItemText primary="Distance (1-9)" />
					</ListItem>
					<ListItem
						button
						onClick={() => {
							distDown();
						}}
						className={classes.nested}
					>
						<ListItemIcon>
							<SwapHorizIcon />
						</ListItemIcon>
						<ListItemText primary="Distance (9-1)" />
					</ListItem>
					<ListItem
						button
						onClick={() => {
							elevUp();
						}}
						className={classes.nested}
					>
						<ListItemIcon>
							<SwapVertIcon />
						</ListItemIcon>
						<ListItemText primary="Elevation (1-9)" />
					</ListItem>
					<ListItem
						button
						onClick={() => {
							elevDown();
						}}
						className={classes.nested}
					>
						<ListItemIcon>
							<SwapVertIcon />
						</ListItemIcon>
						<ListItemText primary="Elevation (9-1)" />
					</ListItem>
					<ListItem
						button
						onClick={() => {
							gradUp();
						}}
						className={classes.nested}
					>
						<ListItemIcon>
							<TrendingUpIcon />
						</ListItemIcon>
						<ListItemText primary="Ø-Gradient (1-9)" />
					</ListItem>
					<ListItem
						button
						onClick={() => {
							gradDown();
						}}
						className={classes.nested}
					>
						<ListItemIcon>
							<TrendingUpIcon />
						</ListItemIcon>
						<ListItemText primary="Ø-Gradient (9-1)" />
					</ListItem>
				</List>
			</Collapse>

			{/* <Typography align="center">
				<p className={classes.supporttext}>Do you want to support ZwiftHub?</p>
			</Typography>

			<CardMedia
				className={classes.media}
				image={cloudinaryCore.url('frydonationzh')}
				title="Shut up and take my money"
			/>

			<Button
				className={classes.donatebutton}
				variant="contained"
				color="primary"
				href="https://www.paypal.me/ZwiftHub/10usd"
				target="_blank"
				rel="noopener noreferrer"
			>
				Donate with paypal
			</Button> */}

			<Divider className={classes.divideradd} />
			<AdSense.Google
				className={classes.add}
				client="ca-pub-3866910374822278"
				slot="9420694213"
				style={{ width: 240, height: 240 }}
				format=""
			/>
		</List>
	);
}
export default memo(NestedList);
