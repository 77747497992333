import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

export default function News(props) {
	const handleCloseNews = () => {
		props.setOpenNews(false);
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(
		() => {
			if (props.openNews) {
				const { current: descriptionElement } = descriptionElementRef;
				if (descriptionElement !== null) {
					descriptionElement.focus();
				}
			}
		},
		[ props.openNews ]
	);

	return (
		<div>
			<Dialog
				open={props.openNews}
				onClose={handleCloseNews}
				maxWidth="md"
				scroll="paper"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">Changelog</DialogTitle>
				<DialogContent dividers={true}>
					<Typography
						style={{ color: 'rgba(0, 0, 0, 0.54)' }}
						component={'span'}
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
					>
						{' '}
						<p>
							<strong>01.05.2021</strong>
						</p>
						<ul>
							<li>ZwiftHub is now officially part of the Zwift Insider family</li>
						</ul>
						<p>
							<strong>01.03.2021</strong>
						</p>
						<ul>
							<li>Further updated some Strava links</li>
						</ul>
						<p>
							<strong>23.02.2021</strong>
						</p>
						<ul>
							<li>Updated some Strava links</li>
							<li>Removed "Fry" from the sidebar</li>
						</ul>
						<br />
						<p>
							<strong>29.01.2021</strong>
						</p>
						<ul>
							<li>Updated guest world schedule (kicked out Paris/France according to Zwift)</li>
							<li>Fixed timezone setting for 'Only today's worlds'-button</li>
						</ul>
						<br />
						<p>
							<strong>31.12.2020</strong>
						</p>
						<ul>
							<li>
								Traffic skyrocketed in the last days! Awesome, but had I had to adjust server and image
								CDN capacities and pay more to get into a higher tier and keep it up and running.
							</li>
							<li>
								Added donation link to the sidebar... over 5k daily unique visitors eating up a lot of
								bandwith...
							</li>
							<li>Logo back to normal and minor fixes</li>
						</ul>
						<br />
						<p>
							<strong>19.12.2020</strong>
						</p>
						<ul>
							<li>Added 4 new Watopia routes</li>
							<li>Updated achievements to include new routes</li>
							<li>Adjusted the logo to a festive version. Merry Xmas, stay safe & healthy everyone!</li>
						</ul>
						<br />
						<p>
							<strong>01.12.2020</strong>
						</p>
						<ul>
							<li>Working in the background on some new features</li>
							<li>Some updates</li>
						</ul>
						<br />
						<p>
							<strong>31.10.2020</strong>
						</p>
						<ul>
							<li>
								Logo is back to normal. Sorry Vuelta, the colors of the Spanish flag didn't work with my
								logo...
							</li>
							<li>Some minor fixes</li>
						</ul>
						<br />
						<p>
							<strong>30.09.2020</strong>
						</p>
						<ul>
							<li>Logo Update for the Giro d'Italia</li>
							<li>Some small bugfixes (and maps)</li>
						</ul>
						<br />
						<p />
						<p>
							<strong>24.09.2020</strong>
						</p>
						<ul>
							<li>Logo Update for the UCI World Championships</li>
							<li>
								WARNING BUG: Zwift badges don't get rewarded at the moment for Four Horsemen & Uber
								Pretzel (IS FIXED NOW!)
							</li>
							<li>Working on GDPR and EU User consent</li>
						</ul>
						<br />
						<p />
						<p>
							<strong>16.09.2020</strong>
						</p>
						<ul>
							<li>Fixed Lutscher XP</li>
							<li>Updated guest world schedule</li>
						</ul>
						<br />
						<p />
						<p>
							<strong>01.09.2020</strong>
						</p>
						<ul>
							<li>Smaller updates / bugfixes</li>
							<li>Adjusted the icon colors of France and Paris</li>
							<li>
								Adjusted GDPR and EU User consent (still not 100% working...) - I hate that I have to
								waste my time looking into that and annoy users with a pop up.
							</li>
							<li>Fixed some strava / ZI links</li>
						</ul>
						<br />
						<p>
							<strong>04.08.2020</strong>
						</p>
						<ul>
							<li>Integration of France and Paris in the Today's world schedule</li>
							<li>Added GDPR consent popup</li>
							<li>Bugfixes and preparation of new features</li>
							<li>Update of some course and lead-in info</li>
						</ul>
						<br />
						<p>
							<strong>25.07.2020</strong>
						</p>
						<ul>
							<li>Run, ZwiftHub, Run!</li>
							<li>Big design and functionality overhaul to include Zwift Running</li>
							<li>Added the running only routes (km & mi) + badges to ZwiftHub</li>
							<li>Created achievement overview for Zwift Running</li>
							<li>
								Some behind the scene magic to prepare for future functions (save filters & settings -
								coming one day...)
							</li>
						</ul>
						<br />
						<p>
							<strong>22.07.2020</strong>
						</p>
						<ul>
							<li>Added maps to France & Paris </li>
							<li>Added KOMs, Sprints & Lap Jerseys for France & Paris</li>
							<li>Fixed small mistakes</li>
						</ul>
						<br />
						<p>
							<strong>09.07.2020</strong>
						</p>
						<ul>
							<li>Added Shane Miller's how-to video to the help section </li>
						</ul>
						<br />
						<p>
							<strong>05.07.2020</strong>
						</p>
						<ul>
							<li>Small bugfixes</li>
						</ul>
						<br />
						<p />
						<p>
							<strong>03.07.2020</strong>
						</p>
						<ul>
							<li>Updated some route data</li>
							<li>Added maps to France & Paris</li>
							<li>Fixed current worlds (guest world schedule)</li>
						</ul>
						<br />
						<p />
						<p>
							<strong>01.07.2020</strong>
						</p>
						<ul>
							<li>It's official! Tour de France will be on Zwift</li>
							<li>Route profiles of France & Paris are updated (in km)</li>
							<li>Lead-Ins are updated</li>
							<li>Strava Segments of France & Paris are updated</li>
							<li>Profiles in miles, segment selection, maps etc. will come soon</li>
						</ul>
						<br />
						<p />
						<p>
							<strong>27.06.2020</strong>
						</p>
						<ul>
							<li>Big Paris / France update!</li>
							<li>Added 9 new routes (7 France, 2 Paris)</li>
							<li>Added Paris and France to the world selection</li>
							<li>Adjusted the achievements to include the new routes</li>
							<li>Added placeholders for the new route elevation profiles</li>
						</ul>
						<br />
						<p />
						<p>
							<strong>13.06.2020</strong>
						</p>
						<ul>
							<li>Adjusted the logo in anticipation of big news to come...</li>
							<li>'Unemployment'-badge is now 'Work from Home'-badge. Same same, but different.</li>
						</ul>
						<br />
						<p />
						<p>
							<strong>01.06.2020</strong>
						</p>
						<ul>
							<li>Minor updates</li>
						</ul>
						<br />
						<p>
							<strong>17.05.2020</strong>
						</p>
						<ul>
							<li>More code cleanup</li>
							<li>Trying out google adsense</li>
						</ul>
						<br />
						<p />
						<p>
							<strong>15.05.2020</strong>
						</p>
						<ul>
							<li>Did some behind the scenes magic (cleaned up code)</li>
							<li>Updated Open Graph data for a prettier display when sharing on social media</li>
						</ul>
						<br />
						<p>
							<strong>02.05.2020</strong>
						</p>
						<ul>
							<li>Links to strava segments</li>
							<li>Links to route details of Zwift Insider</li>
							<li>Minor changes</li>
						</ul>
						<br />
						<p>
							<strong>30.04.2020</strong>
						</p>
						<ul>
							<li>Perfomance and stability updates</li>
							<li>Minor bugfixes</li>
						</ul>
						<br />
						<p>
							<strong>22.04.2020 (2)</strong>
						</p>
						<ul>
							<li>Fixed error (white screen) for some people with the achievement overview</li>
						</ul>
						<br />
						<p>
							<strong>22.04.2020</strong>
						</p>
						<ul>
							<li>Added average gradient in route cards</li>
							<li>Adjusted font-size in route cards</li>
							<li>Added new sort option (gradient)</li>
							<li>Minor fixes</li>
						</ul>
						<br />
						<p>
							<strong>18.04.2020</strong>
						</p>
						<ul>
							<li>Improved and extended achievement overview</li>
							<li>Added 'donation'-section</li>
						</ul>
						<br />
						<p>
							<strong>15.04.2020</strong>
						</p>
						<ul>
							<li>Added 'Badges'-section and tracking</li>
							<li>Updated 'Get Started'-section</li>
							<li>Minor bugfixes</li>
						</ul>
						<br />
						<p>
							<strong>08.04.2020</strong>
						</p>
						<ul>
							<li>Added Lead-ins and Achievement XP</li>
							<li>Extended display settings for signed in users</li>
							<li>Removed 'News'-section and added 'Changelog'-section</li>
							<li>Refreshed 'About'-section</li>
							<li>Updated 'Get Started'-section</li>
						</ul>
						<br />
						<p>
							<strong>06.04.2020</strong>
						</p>{' '}
						<ul>
							<li>Added miles/feet to route cards, elevation charts and filters</li>
							<li>Added display settings for signed in users</li>
						</ul>
						<br />
						<p>
							<strong>04.04.2020</strong>
						</p>{' '}
						<ul>
							<li>Adjusted "Today's worlds only"-switch to two guest worlds</li>
							<li>Implemented minor fixes</li>
							<li>Improved image CDN performance</li>
						</ul>
						<br />
						<p>
							{' '}
							<strong>30.03.2020</strong>
						</p>
						<ul>
							<li>Added 'Get started'-section</li>
							<li>Improved code/performance</li>
						</ul>
						<br />
						<p>
							{' '}
							<strong>28.03.2020</strong>
						</p>
						<ul>
							<li>Project launch</li>
						</ul>
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseNews} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
