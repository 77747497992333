// Code from https://gist.github.com/jherax/f11d669ba286f21b7a2dcff69621eb72
// maybe also interesting
// https://gist.github.com/michalvichy/9647bd41b6dba147a0068149e2b4961a

export default function filterArray(array, filters) {
	const filterKeys = Object.keys(filters);
	return array.filter((item) => {
		// validates all filter criteria
		return filterKeys.every((key) => {
			// ignores non-function predicates
			if (typeof filters[key] !== 'function') return true;
			return filters[key](item[key]);
		});
	});
}
