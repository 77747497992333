import React, { useState, memo, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import BadgeIcon from './BadgeIcon';
import Grid from '@material-ui/core/Grid';
import BadgeCard from './BadgeCard';
import badgedata from '../data/badgedata';
import { AuthCheck } from 'reactfire';
import { WorldFilterContext } from '../contexts/WorldFilter.context';

const initBadgedata = badgedata;

const generalbadges = initBadgedata.slice(0, 12);
const cyclingbadges = initBadgedata.slice(12, 29);
const extracredit = initBadgedata.slice(29, 39);

const useStyles = makeStyles((theme) => ({
	grid: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'spaceevenly',
		flexGrow: 1
	},
	appBar: {
		position: 'relative'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	justdemo: {
		padding: '15px 5px 5px'
	}
}));

function BadgeDialog(props) {
	const classes = useStyles();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	// Import from context
	const { resultbadgescycling } = useContext(WorldFilterContext);

	//handler for sign in dialog
	const [ openSignin, setOpenSignin ] = useState(false);

	const notloggedinhandler = () => {
		setOpenSignin(true);
	};

	const handleCloseSignIn = () => {
		setOpenSignin(false);
	};

	//

	return (
		<div>
			<Dialog
				fullScreen={fullScreen}
				fullWidth={true}
				open={props.openBadgeDialog}
				onClose={props.handleClickCloseBadgeDialog}
				maxWidth="md"
				scroll="paper"
				aria-labelledby="responsive-dialog-title"
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<BadgeIcon />
						<Typography variant="h6" className={classes.title}>
							Badges
						</Typography>
						<Typography variant="h6">{resultbadgescycling}/39</Typography>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<Typography variant="h6">General Badges</Typography>
					<Grid container spacing={2} className={classes.grid}>
						{generalbadges.map((item) => (
							<Grid key={item.badgeid} item xs={6} sm={3} md={2} lg={2} xl={2}>
								<BadgeCard notloggedinhandler={notloggedinhandler} key={item.badgeid} {...item} />
							</Grid>
						))}
					</Grid>
					<br />
					<Typography variant="h6">Cycling Badges</Typography>
					<Grid container spacing={2} className={classes.grid}>
						{cyclingbadges.map((item) => (
							<Grid key={item.badgeid} item xs={6} sm={3} md={2} lg={2} xl={2}>
								<BadgeCard notloggedinhandler={notloggedinhandler} key={item.badgeid} {...item} />
							</Grid>
						))}
					</Grid>
					<br />
					<Typography variant="h6">Extra Credit</Typography>
					<Grid container spacing={2} className={classes.grid}>
						{extracredit.map((item) => (
							<Grid key={item.badgeid} item xs={6} sm={3} md={2} lg={2} xl={2}>
								<BadgeCard notloggedinhandler={notloggedinhandler} key={item.badgeid} {...item} />
							</Grid>
						))}
					</Grid>
					<AuthCheck
						fallback={
							<Typography align="center" color="primary" className={classes.justdemo}>
								This is just an demo of the badge tracking. Sign in to track your own badges.
							</Typography>
						}
					>
						{' '}
					</AuthCheck>
				</DialogContent>
				<DialogActions>
					<Button onClick={props.handleClickCloseBadgeDialog} color="primary" autoFocus>
						Close
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openSignin}
				onClose={handleCloseSignIn}
				onClick={handleCloseSignIn}
				aria-labelledby="not logged in"
			>
				<DialogContent>
					<DialogContentText align="center" id="alert-dialog-description">
						You are not signed in. Sign in to track your badges.
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
}
export default memo(BadgeDialog);
