import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	komchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function KomNewYorkFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleKom, chosenkom } = useContext(WorldFilterContext);

	// Definition of the toggle states for the Koms

	const [ isNewYorkKom, toggleIsNewYorkKom ] = useToggle(chosenkom.includes('New York KOM'));
	const [ isNewYorkKomRev, toggleIsNewYorkKomRev ] = useToggle(chosenkom.includes('New York KOM Rev.'));

	return (
		<div className={classes.root}>
			<div className={classes.komchips}>
				<Chip
					className={classes.chip}
					label="New York KOM"
					onClick={() => {
						toggleKom('New York KOM');
						toggleIsNewYorkKom();
					}}
					variant={isNewYorkKom ? 'default' : 'outlined'}
					color={isNewYorkKom ? 'primary' : 'default'}
					style={{ color: isNewYorkKom ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="NY KOM Rev."
					onClick={() => {
						toggleKom('New York KOM Rev.');
						toggleIsNewYorkKomRev();
					}}
					variant={isNewYorkKomRev ? 'default' : 'outlined'}
					color={isNewYorkKomRev ? 'primary' : 'default'}
					style={{ color: isNewYorkKomRev ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(KomNewYorkFilter);
