import React, { useState, memo, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import KomWatopiaFilter from './KomWatopiaFilter';
import KomLondonFilter from './KomLondonFilter';
import KomNewYorkFilter from './KomNewYorkFilter';
import KomInnsbruckFilter from './KomInnsbruckFilter';
import KomYorkshireFilter from './KomYorkshireFilter';
import KomRichmondFilter from './KomRichmondFilter';
import KomFranceFilter from './KomFranceFilter';
import KomParisFilter from './KomParisFilter';
import KomMakuriFilter from './KomMakuriFilter';
import KomScotlandFilter from './KomScotlandFilter';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper
	},
	nested: {
		paddingLeft: theme.spacing(4)
	},
	KomHeaders: {
		color: '#fe0100'
	},
	container: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap'
	}
}));

function NavKomList() {
	const classes = useStyles();

	// Import from context
	const { setchosenkom } = useContext(WorldFilterContext);

	// KOM subheaders accordeon action
	const [ activeKom, setActiveKom ] = useState(true);

	const handleClickKomWatopia = () => {
		setActiveKom(activeKom === 'KomWatopia' ? true : 'KomWatopia');
	};
	const handleClickKomLondon = () => {
		setActiveKom(activeKom === 'KomLondon' ? true : 'KomLondon');
	};
	const handleClickKomNewYork = () => {
		setActiveKom(activeKom === 'KomNewYork' ? true : 'KomNewYork');
	};
	const handleClickKomInnsbruck = () => {
		setActiveKom(activeKom === 'KomInnsbruck' ? true : 'KomInnsbruck');
	};
	const handleClickKomYorkshire = () => {
		setActiveKom(activeKom === 'KomYorkshire' ? true : 'KomYorkshire');
	};
	const handleClickKomRichmond = () => {
		setActiveKom(activeKom === 'KomRichmond' ? true : 'KomRichmond');
	};
	const handleClickKomFrance = () => {
		setActiveKom(activeKom === 'KomFrance' ? true : 'KomFrance');
	};
	const handleClickKomParis = () => {
		setActiveKom(activeKom === 'KomParis' ? true : 'KomParis');
	};
	const handleClickKomMakuri = () => {
		setActiveKom(activeKom === 'KomMakuri' ? true : 'KomMakuri');
	};
	const handleClickKomScotland = () => {
		setActiveKom(activeKom === 'KomScotland' ? true : 'KomScotland');
	};
	//

	return (
		<List component="div" disablePadding className={classes.container}>
			<Chip
				className={classes.chip}
				onClick={() => {
					setchosenkom([]);
					setActiveKom(false);
				}}
				size="small"
				label="reset all KOMs"
				variant="outlined"
				color="secondary"
			/>
			<ListItem button onClick={handleClickKomWatopia} className={classes.nested}>
				<ListItemText className={classes.KomHeaders} primary="Watopia" />
				{activeKom === 'KomWatopia' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeKom === 'KomWatopia'} timeout="auto" unmountOnExit>
				<KomWatopiaFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickKomLondon} className={classes.nested}>
				<ListItemText className={classes.KomHeaders} primary="London" />
				{activeKom === 'KomLondon' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeKom === 'KomLondon'} timeout="auto" unmountOnExit>
				<KomLondonFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickKomNewYork} className={classes.nested}>
				<ListItemText className={classes.KomHeaders} primary="New York" />
				{activeKom === 'KomNewYork' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeKom === 'KomNewYork'} timeout="auto" unmountOnExit>
				<KomNewYorkFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickKomInnsbruck} className={classes.nested}>
				<ListItemText className={classes.KomHeaders} primary="Innsbruck" />
				{activeKom === 'KomInnsbruck' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeKom === 'KomInnsbruck'} timeout="auto" unmountOnExit>
				<KomInnsbruckFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickKomYorkshire} className={classes.nested}>
				<ListItemText className={classes.KomHeaders} primary="Yorkshire" />
				{activeKom === 'KomYorkshire' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeKom === 'KomYorkshire'} timeout="auto" unmountOnExit>
				<KomYorkshireFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickKomRichmond} className={classes.nested}>
				<ListItemText className={classes.KomHeaders} primary="Richmond" />
				{activeKom === 'KomRichmond' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeKom === 'KomRichmond'} timeout="auto" unmountOnExit>
				<KomRichmondFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickKomFrance} className={classes.nested}>
				<ListItemText className={classes.KomHeaders} primary="France" />
				{activeKom === 'KomFrance' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeKom === 'KomFrance'} timeout="auto" unmountOnExit>
				<KomFranceFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickKomParis} className={classes.nested}>
				<ListItemText className={classes.KomHeaders} primary="Paris" />
				{activeKom === 'KomParis' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeKom === 'KomParis'} timeout="auto" unmountOnExit>
				<KomParisFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickKomMakuri} className={classes.nested}>
				<ListItemText className={classes.KomHeaders} primary="Makuri" />
				{activeKom === 'KomMakuri' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeKom === 'KomMakuri'} timeout="auto" unmountOnExit>
				<KomMakuriFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickKomScotland} className={classes.nested}>
				<ListItemText className={classes.KomHeaders} primary="Scotland" />
				{activeKom === 'KomScotland' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeKom === 'KomScotland'} timeout="auto" unmountOnExit>
				<KomScotlandFilter />
			</Collapse>
			<Divider />
		</List>
	);
}
export default memo(NavKomList);
