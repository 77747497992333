import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { FirebaseAppProvider } from 'reactfire';
import * as firebase from 'firebase/app';
import 'firebase/analytics';

// Configure Firebase.
const firebaseConfig = {
	apiKey: 'AIzaSyDuyvik8Xbl-CBinGPakKL7PW51p2mOv9U',
	authDomain: 'zwifthub.firebaseapp.com',
	databaseURL: 'https://zwifthub.firebaseio.com',
	projectId: 'zwifthub',
	storageBucket: 'zwifthub.appspot.com',
	messagingSenderId: '551241178782',
	appId: '1:551241178782:web:7b748023ce652b5193e58f',
	measurementId: 'G-Y2F8HZ84TF'
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// mode before I switched to concurrent
//ReactDOM.render(<App />, document.getElementById('root'));

//concurrent mode
ReactDOM.createRoot(document.getElementById('root')).render(
	<FirebaseAppProvider firebaseConfig={firebaseConfig}>
		<App />
	</FirebaseAppProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
