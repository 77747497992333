import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	sprintchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function SprintParisFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleSprint, chosensprint } = useContext(WorldFilterContext);

	// Definition of the toggle states for the sprints

	const [ isLuteceSprint, toggleIsLuteceSprint ] = useToggle(chosensprint.includes('Lutece Sprint'));
	const [ isLuteceSprintRev, toggleIsLuteceSprintRev ] = useToggle(chosensprint.includes('Lutece Sprint Rev.'));

	return (
		<div className={classes.root}>
			<div className={classes.sprintchips}>
				<Chip
					className={classes.chip}
					label="Lutece Sprint"
					onClick={() => {
						toggleSprint('Lutece Sprint');
						toggleIsLuteceSprint();
					}}
					variant={isLuteceSprint ? 'default' : 'outlined'}
					color={isLuteceSprint ? 'primary' : 'default'}
					style={{ color: isLuteceSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Lutece Rev."
					onClick={() => {
						toggleSprint('Lutece Sprint Rev.');
						toggleIsLuteceSprintRev();
					}}
					variant={isLuteceSprintRev ? 'default' : 'outlined'}
					color={isLuteceSprintRev ? 'primary' : 'default'}
					style={{ color: isLuteceSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(SprintParisFilter);
