import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	komchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function KomInnsbruckFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleKom, chosenkom } = useContext(WorldFilterContext);

	// Definition of the toggle states for the Koms

	const [ isInnsbruckKom, toggleIsInnsbruckKom ] = useToggle(chosenkom.includes('Innsbruck KOM'));
	const [ isInnsbruckKomRev, toggleIsInnsbruckKomRev ] = useToggle(chosenkom.includes('Innsbruck KOM Rev.'));
	const [ isLegSnapper, toggleIsLegSnapper ] = useToggle(chosenkom.includes('Leg Snapper KOM'));

	return (
		<div className={classes.root}>
			<div className={classes.komchips}>
				<Chip
					className={classes.chip}
					label="Innsbruck KOM"
					onClick={() => {
						toggleKom('Innsbruck KOM');
						toggleIsInnsbruckKom();
					}}
					variant={isInnsbruckKom ? 'default' : 'outlined'}
					color={isInnsbruckKom ? 'primary' : 'default'}
					style={{ color: isInnsbruckKom ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Innsbr. KOM Rev."
					onClick={() => {
						toggleKom('Innsbruck KOM Rev.');
						toggleIsInnsbruckKomRev();
					}}
					variant={isInnsbruckKomRev ? 'default' : 'outlined'}
					color={isInnsbruckKomRev ? 'primary' : 'default'}
					style={{ color: isInnsbruckKomRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Leg Snapper"
					onClick={() => {
						toggleKom('Leg Snapper KOM');
						toggleIsLegSnapper();
					}}
					variant={isLegSnapper ? 'default' : 'outlined'}
					color={isLegSnapper ? 'primary' : 'default'}
					style={{ color: isLegSnapper ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(KomInnsbruckFilter);
