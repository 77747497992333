import React, { memo, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import { WorldFilterContext } from '../contexts/WorldFilter.context';

const useStyles = makeStyles((theme) => ({
	progressIcon: {
		position: 'absolute'
	}
}));

function ProgressionIcon() {
	const classes = useStyles();

	//import achievement list
	const { achievedrouteids } = useContext(WorldFilterContext);

	//achievedrouteidsCycling
	const achievedrouteidsCycling = achievedrouteids.filter((id) => id <= 76 || id >= 88);

	// total achievement percentage
	const achievmentpercent = Math.round(achievedrouteidsCycling.length * 100 / 92); //add number of new routes to total

	return (
		<Avatar style={{ backgroundColor: 'transparent' }}>
			<CircularProgress className={classes.progressIcon} size={35} color="inherit" variant="static" value={100} />
			<CircularProgress
				className={classes.progressIcon}
				size={35}
				color="secondary"
				variant="static"
				value={achievmentpercent}
			/>

			<EmojiEventsIcon className={classes.progressIcon} color="inherit" />
		</Avatar>
	);
}
export default memo(ProgressionIcon);
