import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	komchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function KomLondonFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleKom, chosenkom } = useContext(WorldFilterContext);

	// Definition of the toggle states for the Koms

	const [ isBoxHill, toggleIsBoxHill ] = useToggle(chosenkom.includes('Box Hill'));
	const [ isFoxHill, toggleIsFoxHill ] = useToggle(chosenkom.includes('Fox Hill'));
	const [ isKeithHill, toggleIsKeithHill ] = useToggle(chosenkom.includes('Keith Hill'));
	const [ isLeithHill, toggleIsLeithHill ] = useToggle(chosenkom.includes('Leith Hill'));

	return (
		<div className={classes.root}>
			<div className={classes.komchips}>
				<Chip
					className={classes.chip}
					label="Box Hill"
					onClick={() => {
						toggleKom('Box Hill');
						toggleIsBoxHill();
					}}
					variant={isBoxHill ? 'default' : 'outlined'}
					color={isBoxHill ? 'primary' : 'default'}
					style={{ color: isBoxHill ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Fox Hill"
					onClick={() => {
						toggleKom('Fox Hill');
						toggleIsFoxHill();
					}}
					variant={isFoxHill ? 'default' : 'outlined'}
					color={isFoxHill ? 'primary' : 'default'}
					style={{ color: isFoxHill ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Keith Hill"
					onClick={() => {
						toggleKom('Keith Hill');
						toggleIsKeithHill();
					}}
					variant={isKeithHill ? 'default' : 'outlined'}
					color={isKeithHill ? 'primary' : 'default'}
					style={{ color: isKeithHill ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Leith Hill"
					onClick={() => {
						toggleKom('Leith Hill');
						toggleIsLeithHill();
					}}
					variant={isLeithHill ? 'default' : 'outlined'}
					color={isLeithHill ? 'primary' : 'default'}
					style={{ color: isLeithHill ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(KomLondonFilter);
