import React, { memo, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import { WorldFilterContext } from '../contexts/WorldFilter.context';

const useStyles = makeStyles((theme) => ({
	progressIcon: {
		position: 'absolute'
	}
}));

function ProgressionIconRun() {
	const classes = useStyles();

	//import achievement list
	const { achievedrouteids } = useContext(WorldFilterContext);

	//achievedrouteidsRunning
	const achievedrouteidsRunning = achievedrouteids.filter((id) => id > 76 && id < 88);

	// total achievement percentage
	const achievmentpercent = Math.round(achievedrouteidsRunning.length * 100 / 11);

	return (
		<Avatar style={{ backgroundColor: 'transparent' }}>
			<CircularProgress className={classes.progressIcon} size={35} color="inherit" variant="static" value={100} />
			<CircularProgress
				className={classes.progressIcon}
				size={35}
				color="primary"
				variant="static"
				value={achievmentpercent}
			/>

			<EmojiEventsIcon className={classes.progressIcon} color="inherit" />
		</Avatar>
	);
}
export default memo(ProgressionIconRun);
