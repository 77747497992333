import React, { useContext, memo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../contexts/WorldFilter.context';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	achievementchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '200px',
		maxWidth: '200px'
	}
}));

function NavAchievement() {
	const classes = useStyles();

	// Import from context
	const {
		activeachievementbutton,
		setactiveachievementbutton,
		setfilterachievments,
		achievedrouteids,
		notachievedrouteids
	} = useContext(WorldFilterContext);

	// Definition of the set states for the achievements

	const [ isonlyachieved, setisonlyachieved ] = useState(activeachievementbutton === 'achieved');
	const [ isonlynotachieved, setisonlynotachieved ] = useState(activeachievementbutton === 'not');
	const [ isallroutes, setisallroutes ] = useState(activeachievementbutton === 'all');

	return (
		<div className={classes.root}>
			<div className={classes.achievementchips}>
				<Chip
					className={classes.chip}
					label="only achieved Routes"
					onClick={() => {
						setfilterachievments(achievedrouteids);
						setisonlyachieved(true);
						setisonlynotachieved(false);
						setisallroutes(false);
						setactiveachievementbutton('achieved');
					}}
					variant={isonlyachieved ? 'default' : 'outlined'}
					color={isonlyachieved ? 'primary' : 'default'}
					style={{ color: isonlyachieved ? '' : '#b4b4b4' }}
				/>
				<Chip
					className={classes.chip}
					label="only not achieved Routes"
					onClick={() => {
						setfilterachievments(notachievedrouteids);
						setisonlyachieved(false);
						setisonlynotachieved(true);
						setisallroutes(false);
						setactiveachievementbutton('not');
					}}
					variant={isonlynotachieved ? 'default' : 'outlined'}
					color={isonlynotachieved ? 'primary' : 'default'}
					style={{ color: isonlynotachieved ? '' : '#b4b4b4' }}
				/>
				<Chip
					className={classes.chip}
					label="all Routes"
					onClick={() => {
						setfilterachievments([]);
						setisonlyachieved(false);
						setisonlynotachieved(false);
						setisallroutes(true);
						setactiveachievementbutton('all');
					}}
					variant={isallroutes ? 'default' : 'outlined'}
					color={isallroutes ? 'primary' : 'default'}
					style={{ color: isallroutes ? '' : '#b4b4b4' }}
				/>
			</div>
		</div>
	);
}
export default memo(NavAchievement);
