import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	komchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function KomRichmondFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleKom, chosenkom } = useContext(WorldFilterContext);

	// Definition of the toggle states for the Koms

	const [ is23rdSt, toggleIs23rdSt ] = useToggle(chosenkom.includes('23rd St.'));
	const [ isLibbyHill, toggleIsLibbyHill ] = useToggle(chosenkom.includes('Libby Hill'));

	return (
		<div className={classes.root}>
			<div className={classes.komchips}>
				<Chip
					className={classes.chip}
					label="23rd St."
					onClick={() => {
						toggleKom('23rd St.');
						toggleIs23rdSt();
					}}
					variant={is23rdSt ? 'default' : 'outlined'}
					color={is23rdSt ? 'primary' : 'default'}
					style={{ color: is23rdSt ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Libby Hill"
					onClick={() => {
						toggleKom('Libby Hill');
						toggleIsLibbyHill();
					}}
					variant={isLibbyHill ? 'default' : 'outlined'}
					color={isLibbyHill ? 'primary' : 'default'}
					style={{ color: isLibbyHill ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(KomRichmondFilter);
