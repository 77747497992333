import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

export default function ToSCopyright(props) {
	const handleCloseToSCopyright = () => {
		props.setOpenToSCopyright(false);
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(
		() => {
			if (props.openToSCopyright) {
				const { current: descriptionElement } = descriptionElementRef;
				if (descriptionElement !== null) {
					descriptionElement.focus();
				}
			}
		},
		[ props.openToSCopyright ]
	);

	return (
		<div>
			<Dialog
				open={props.openToSCopyright}
				onClose={handleCloseToSCopyright}
				maxWidth="md"
				scroll="paper"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">ToS &amp; Copyright</DialogTitle>
				<DialogContent dividers={true}>
					<Typography
						style={{ color: 'rgba(0, 0, 0, 0.54)' }}
						component={'span'}
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
					>
						<p>
							By using the web app, these terms will automatically apply to you – you should make sure
							therefore that you read them carefully before using the web app. You’re not allowed to copy,
							or modify the web app, any part of the web app, or our trademarks in any way. You’re not
							allowed to attempt to extract the source code of the web app, and you also shouldn’t try to
							translate the web app into other languages, or make derivative versions. The web app itself,
							and all the trade marks, copyright, database rights and other intellectual property rights
							related to it, still belong to Tobias Fenney.
						</p>{' '}
						<p>
							Tobias Fenney is committed to ensuring that the web app is as useful and efficient as
							possible. For that reason, we reserve the right to make changes to the web app at any time
							and for any reason.
						</p>{' '}
						<p>
							The zwifthub.com web app stores and processes personal data that you have provided to us, in
							order to provide my Service. It’s your responsibility to keep access to the web app secure.
						</p>{' '}
						<p>The web app does use third party services that declare their own Terms of Services.</p>{' '}
						<div>
							<p>Link to Terms of Services of third party service providers used by the app</p>{' '}
							<ul>
								<li>
									<a
										href="https://firebase.google.com/terms"
										target="_blank"
										rel="noopener noreferrer"
									>
										Google Firebase
									</a>
								</li>
								<li>
									<a href="https://cloudinary.com/tos" target="_blank" rel="noopener noreferrer">
										Cloudinary
									</a>
								</li>
							</ul>
						</div>{' '}
						<p>
							You should be aware that there are certain things that Tobias Fenney will not take
							responsibility for. Certain functions of the app will require the app to have an active
							internet connection. The connection can be Wi-Fi, or provided by your mobile network
							provider, but Tobias Fenney cannot take responsibility for the app not working at full
							functionality if you don’t have access to Wi-Fi, and you don’t have any of your data
							allowance left.
						</p>{' '}
						<p />{' '}
						<p>
							With respect to Tobias Fenney’s responsibility for your use of the web app, when you’re
							using the web app, it’s important to bear in mind that although we endeavour to ensure that
							it is updated and correct at all times, we do rely on third parties to provide information
							to us so that we can make it available to you. Tobias Fenney accepts no liability for any
							loss, direct or indirect, you experience as a result of relying wholly on this functionality
							of the app.
						</p>{' '}
						<p>
							At some point, we may wish to update the web app. The web app is currently available on
							www.zwifthub.com – the requirements for system (and for any additional systems we decide to
							extend the availability of the app to) may change. I may also wish to stop providing the web
							app, and may terminate use of it at any time without giving notice of termination to you.
							Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to
							you in these terms will end; (b) you must stop using the web app, and (if needed) delete it
							from your device.
						</p>{' '}
						<p>
							<strong>Digital Millennium Copyright Act ("DMCA")</strong>
						</p>{' '}
						<p>
							Tobias Fenney (ZwiftHub) respects the intellectual property rights of others. Per the DMCA,
							Tobias Fenney (ZwiftHub) will respond expeditiously to claims of copyright infringement on
							the Site if submitted to Tobias Fenney (ZwiftHub)'s Copyright Agent as described below. Upon
							receipt of a notice alleging copyright infringement, Tobias Fenney (ZwiftHub) will take
							whatever action it deems appropriate within its sole discretion, including removal of the
							allegedly infringing materials and termination of access for repeat infringers of copyright
							protected content.
						</p>
						<p>
							If you believe that your intellectual property rights have been violated by Tobias Fenney
							(ZwiftHub) or by a third party who has uploaded materials to our website, please provide the
							following information to the designated Copyright Agent listed below:
						</p>
						<ul>
							<li>
								A description of the copyrighted work or other intellectual property that you claim has
								been infringed;
							</li>
							<li>
								A description of where the material that you claim is infringing is located on the Site;
							</li>
							<li>
								An address, telephone number, and email address where we can contact you and, if
								different, an email address where the alleged infringing party, if not Tobias Fenney
								(ZwiftHub), can contact you;
							</li>
							<li>
								A statement that you have a good-faith belief that the use is not authorized by the
								copyright owner or other intellectual property rights owner, by its agent, or by law;
							</li>
							<li>
								A statement by you under penalty of perjury that the information in your notice is
								accurate and that you are the copyright or intellectual property owner or are authorized
								to act on the owner's behalf;
							</li>
							<li>Your electronic or physical signature.</li>
						</ul>
						<p>
							Tobias Fenney (ZwiftHub) may request additional information before removing any allegedly
							infringing material. In the event Tobias Fenney (ZwiftHub) removes the allegedly infringing
							materials, Tobias Fenney (ZwiftHub) will immediately notify the person responsible for
							posting such materials that Tobias Fenney (ZwiftHub) removed or disabled access to the
							materials. Tobias Fenney (ZwiftHub) may also provide the responsible person with your email
							address so that the person may respond to your allegations.
						</p>
						<p>
							<strong>Changes to this Terms of Services</strong>
						</p>{' '}
						<p>
							I may update our Terms of Services from time to time. Thus, you are advised to review this
							page periodically for any changes. I will notify you of any changes by posting the new Terms
							of Services on this page. These changes are effective immediately after they are posted on
							this page.
						</p>{' '}
						<p>
							<strong>Contact Me</strong>
						</p>{' '}
						<p>
							If you have any questions or suggestions about my Terms of Services or Copyright issues, do
							not hesitate to contact me at toby@zwifthub.com.
						</p>
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseToSCopyright} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
