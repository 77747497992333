import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	sprintchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function SprintRichmondFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleSprint, chosensprint } = useContext(WorldFilterContext);

	// Definition of the toggle states for the sprints

	const [ isBroadSt, toggleIsBroadSt ] = useToggle(chosensprint.includes('Broad St.'));
	const [ isMonumentAve, toggleIsMonumentAve ] = useToggle(chosensprint.includes('Monument Ave'));
	const [ isBroadStRev, toggleIsBroadStRev ] = useToggle(chosensprint.includes('Broad St. Rev'));
	const [ isMonumentAveRev, toggleIsMonumentAveRev ] = useToggle(chosensprint.includes('Monument Ave Rev'));

	return (
		<div className={classes.root}>
			<div className={classes.sprintchips}>
				<Chip
					className={classes.chip}
					label="Broad St."
					onClick={() => {
						toggleSprint('Broad St.');
						toggleIsBroadSt();
					}}
					variant={isBroadSt ? 'default' : 'outlined'}
					color={isBroadSt ? 'primary' : 'default'}
					style={{ color: isBroadSt ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Broad St. Rev"
					onClick={() => {
						toggleSprint('Broad St. Rev');
						toggleIsBroadStRev();
					}}
					variant={isBroadStRev ? 'default' : 'outlined'}
					color={isBroadStRev ? 'primary' : 'default'}
					style={{ color: isBroadStRev ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Monument Ave"
					onClick={() => {
						toggleSprint('Monument Ave');
						toggleIsMonumentAve();
					}}
					variant={isMonumentAve ? 'default' : 'outlined'}
					color={isMonumentAve ? 'primary' : 'default'}
					style={{ color: isMonumentAve ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Monument Ave Rev"
					onClick={() => {
						toggleSprint('Monument Ave Rev');
						toggleIsMonumentAveRev();
					}}
					variant={isMonumentAveRev ? 'default' : 'outlined'}
					color={isMonumentAveRev ? 'primary' : 'default'}
					style={{ color: isMonumentAveRev ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(SprintRichmondFilter);
