import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export default function Contact(props) {
	const handleCloseContact = () => {
		props.setOpenContact(false);
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(
		() => {
			if (props.openContact) {
				const { current: descriptionElement } = descriptionElementRef;
				if (descriptionElement !== null) {
					descriptionElement.focus();
				}
			}
		},
		[ props.openContact ]
	);

	return (
		<Dialog
			open={props.openContact}
			onClose={handleCloseContact}
			maxWidth="md"
			scroll="paper"
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle id="scroll-dialog-title">Contact Me</DialogTitle>
			<DialogContent dividers={true}>
				<Typography
					style={{ color: 'rgba(0, 0, 0, 0.54)' }}
					component={'span'}
					id="scroll-dialog-description"
					ref={descriptionElementRef}
					tabIndex={-1}
				>
					<p>
						Chatting me up whilst riding on Zwift is an option, but I might be too much out of breath for an
						answer...
					</p>
					<p>The simpler way to give me your feedback or ask questions is to contact me via email:</p>
					<Link href="mailto:zwifthub@zwiftinsider.com?Subject=ZwiftHub%20Feedback" target="_top">
						zwifthub@zwiftinsider.com
					</Link>
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseContact} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}
