import React, { memo, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import StarIcon from '@material-ui/icons/Star';
import Avatar from '@material-ui/core/Avatar';
import { WorldFilterContext } from '../contexts/WorldFilter.context';

const useStyles = makeStyles((theme) => ({
	progressIcon: {
		position: 'absolute'
	}
}));

function BadgeIcon() {
	const classes = useStyles();

	//import achievement list
	const { resultbadgescycling } = useContext(WorldFilterContext);

	// total achievement percentage
	const achievmentpercent = Math.round(resultbadgescycling * 100 / 39);

	return (
		<Avatar style={{ backgroundColor: 'transparent' }}>
			<CircularProgress className={classes.progressIcon} size={35} color="inherit" variant="static" value={100} />
			<CircularProgress
				className={classes.progressIcon}
				size={35}
				color="secondary"
				variant="static"
				value={achievmentpercent}
			/>

			<StarIcon className={classes.progressIcon} color="inherit" />
		</Avatar>
	);
}
export default memo(BadgeIcon);
