import React, { createContext } from 'react';
import useWorldFilter from '../hooks/useWorldFilter';

export const WorldFilterContext = createContext();

export function WorldFilterProvider(props) {
	const worldStuff = useWorldFilter();

	return <WorldFilterContext.Provider value={worldStuff}>{props.children}</WorldFilterContext.Provider>;
}
