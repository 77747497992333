import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	lapchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function LapLondonFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleLap, chosenlap } = useContext(WorldFilterContext);

	// Definition of the toggle states for the Laps

	const [ isLondonLoop, toggleIsLondonLoop ] = useToggle(chosenlap.includes('London Loop'));

	return (
		<div className={classes.root}>
			<div className={classes.lapchips}>
				<Chip
					className={classes.chip}
					label="London Loop"
					onClick={() => {
						toggleLap('London Loop');
						toggleIsLondonLoop();
					}}
					variant={isLondonLoop ? 'default' : 'outlined'}
					color={isLondonLoop ? 'primary' : 'default'}
					style={{ color: isLondonLoop ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(LapLondonFilter);
