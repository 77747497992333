import React, { useState, memo, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SprintWatopiaFilter from './SprintWatopiaFilter';
import SprintLondonFilter from './SprintLondonFilter';
import SprintNewYorkFilter from './SprintNewYorkFilter';
import SprintInnsbruckFilter from './SprintInnsbruckFilter';
import SprintYorkshireFilter from './SprintYorkshireFilter';
import SprintRichmondFilter from './SprintRichmondFilter';
import SprintFranceFilter from './SprintFranceFilter';
import SprintParisFilter from './SprintParisFilter';
import SprintMakuriFilter from './SprintMakuriFilter';
import SprintScotlandFilter from './SprintScotlandFilter';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper
	},
	nested: {
		paddingLeft: theme.spacing(4)
	},
	SprintHeaders: {
		color: '#fe0100'
	},
	container: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap'
	}
}));

function NavSprintList() {
	const classes = useStyles();

	// Import from context
	const { setchosensprint } = useContext(WorldFilterContext);

	// Sprint subheaders accordeon action
	const [ activeSprint, setActiveSprint ] = useState(true);

	const handleClickSprintWatopia = () => {
		setActiveSprint(activeSprint === 'SprintWatopia' ? true : 'SprintWatopia');
	};
	const handleClickSprintLondon = () => {
		setActiveSprint(activeSprint === 'SprintLondon' ? true : 'SprintLondon');
	};
	const handleClickSprintNewYork = () => {
		setActiveSprint(activeSprint === 'SprintNewYork' ? true : 'SprintNewYork');
	};
	const handleClickSprintInnsbruck = () => {
		setActiveSprint(activeSprint === 'SprintInnsbruck' ? true : 'SprintInnsbruck');
	};
	const handleClickSprintYorkshire = () => {
		setActiveSprint(activeSprint === 'SprintYorkshire' ? true : 'SprintYorkshire');
	};
	const handleClickSprintRichmond = () => {
		setActiveSprint(activeSprint === 'SprintRichmond' ? true : 'SprintRichmond');
	};
	const handleClickSprintFrance = () => {
		setActiveSprint(activeSprint === 'SprintFrance' ? true : 'SprintFrance');
	};
	const handleClickSprintParis = () => {
		setActiveSprint(activeSprint === 'SprintParis' ? true : 'SprintParis');
	};
	const handleClickSprintMakuri = () => {
		setActiveSprint(activeSprint === 'SprintMakuri' ? true : 'SprintMakuri');
	};
	const handleClickSprintScotland = () => {
		setActiveSprint(activeSprint === 'SprintScotland' ? true : 'SprintScotland');
	};
	//

	return (
		<List component="div" disablePadding className={classes.container}>
			<Chip
				className={classes.chip}
				onClick={() => {
					setchosensprint([]);
					setActiveSprint(false);
				}}
				size="small"
				label="reset all Sprints"
				variant="outlined"
				color="secondary"
			/>
			<ListItem button onClick={handleClickSprintWatopia} className={classes.nested}>
				<ListItemText className={classes.SprintHeaders} primary="Watopia" />
				{activeSprint === 'SprintWatopia' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeSprint === 'SprintWatopia'} timeout="auto" unmountOnExit>
				<SprintWatopiaFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickSprintLondon} className={classes.nested}>
				<ListItemText className={classes.SprintHeaders} primary="London" />
				{activeSprint === 'SprintLondon' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeSprint === 'SprintLondon'} timeout="auto" unmountOnExit>
				<SprintLondonFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickSprintNewYork} className={classes.nested}>
				<ListItemText className={classes.SprintHeaders} primary="New York" />
				{activeSprint === 'SprintNewYork' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeSprint === 'SprintNewYork'} timeout="auto" unmountOnExit>
				<SprintNewYorkFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickSprintInnsbruck} className={classes.nested}>
				<ListItemText className={classes.SprintHeaders} primary="Innsbruck" />
				{activeSprint === 'SprintInnsbruck' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeSprint === 'SprintInnsbruck'} timeout="auto" unmountOnExit>
				<SprintInnsbruckFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickSprintYorkshire} className={classes.nested}>
				<ListItemText className={classes.SprintHeaders} primary="Yorkshire" />
				{activeSprint === 'SprintYorkshire' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeSprint === 'SprintYorkshire'} timeout="auto" unmountOnExit>
				<SprintYorkshireFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickSprintRichmond} className={classes.nested}>
				<ListItemText className={classes.SprintHeaders} primary="Richmond" />
				{activeSprint === 'SprintRichmond' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeSprint === 'SprintRichmond'} timeout="auto" unmountOnExit>
				<SprintRichmondFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickSprintFrance} className={classes.nested}>
				<ListItemText className={classes.SprintHeaders} primary="France" />
				{activeSprint === 'SprintFrance' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeSprint === 'SprintFrance'} timeout="auto" unmountOnExit>
				<SprintFranceFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickSprintParis} className={classes.nested}>
				<ListItemText className={classes.SprintHeaders} primary="Paris" />
				{activeSprint === 'SprintParis' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeSprint === 'SprintParis'} timeout="auto" unmountOnExit>
				<SprintParisFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickSprintMakuri} className={classes.nested}>
				<ListItemText className={classes.SprintHeaders} primary="Makuri" />
				{activeSprint === 'SprintMakuri' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeSprint === 'SprintMakuri'} timeout="auto" unmountOnExit>
				<SprintMakuriFilter />
			</Collapse>
			<Divider />

			<ListItem button onClick={handleClickSprintScotland} className={classes.nested}>
				<ListItemText className={classes.SprintHeaders} primary="Scotland" />
				{activeSprint === 'SprintScotland' ? (
					<ExpandLess style={{ color: '#fe0100' }} fontSize="small" />
				) : (
					<ExpandMore style={{ color: '#fe0100' }} fontSize="small" />
				)}
			</ListItem>
			<Collapse in={activeSprint === 'SprintScotland'} timeout="auto" unmountOnExit>
				<SprintScotlandFilter />
			</Collapse>
			<Divider />
		</List>
	);
}
export default memo(NavSprintList);
