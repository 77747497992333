import React, { useContext, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { WorldFilterContext } from "../contexts/WorldFilter.context";
import ProgressionIconTo from "./ProgressionIconTo";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import { AuthCheck } from "reactfire";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import LandscapeIcon from "@material-ui/icons/Landscape";
import StarIcon from "@material-ui/icons/Star";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  avatar: {
    width: 35,
    height: 35,
  },
  card: {
    width: 250,
    marginTop: "1rem",
  },
  iconcontainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  justdemo: {
    padding: "15px 5px 5px",
  },
  cardheader: {
    padding: "4px 8px",
  },
  overviewcontainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px 8px",
  },
  textoverview: {
    fontSize: "0.875rem",
  },
  textsubheader: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.75rem",
  },
  headline: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "4px",
  },
  progressIcon: {
    position: "absolute",
  },
}));

function AchievementDrawer() {
  const classes = useStyles();

  //import achievement list
  const {
    userachievements,
    initialRoutes,
    achievedrouteids,
    resultbadgescycling,
    displaymiles,
  } = useContext(WorldFilterContext);

  // partial of the different Worlds
  function inRange(x) {
    return this[0] <= x && x <= this[1];
  }
  //route ids
  const routeids = userachievements.map((el) => el.slice(8));

  //cycling route ids
  const routeidsCycling = routeids.filter((id) => id <= 76 || id >= 88 && id <=135 || id >=137 && id <=182);

  //achievedrouteidsCycling
  const achievedrouteidsCycling = achievedrouteids.filter(
    (id) => id <= 76 || id >= 88 && id <=135 || id >=137 && id <=182 
  );

  //total no of cycling routes out of initials
  const initialCyclingRoutes = initialRoutes.filter(
    (route) => route.sport === "cycling"
  );

  // number of achievements filter by world
  const totalWa = initialCyclingRoutes.filter(
    (route) => route.world === "Watopia"
  ).length;
  const totalLo = initialCyclingRoutes.filter(
    (route) => route.world === "London"
  ).length;
  const totalNe = initialCyclingRoutes.filter(
    (route) => route.world === "New York"
  ).length;
  const totalIn = initialCyclingRoutes.filter(
    (route) => route.world === "Innsbruck"
  ).length;
  const totalYo = initialCyclingRoutes.filter(
    (route) => route.world === "Yorkshire"
  ).length;
  const totalRi = initialCyclingRoutes.filter(
    (route) => route.world === "Richmond"
  ).length;
  const totalFr = initialCyclingRoutes.filter(
    (route) => route.world === "France"
  ).length;
  const totalPa = initialCyclingRoutes.filter(
    (route) => route.world === "Paris"
  ).length;
  const totalMa = initialCyclingRoutes.filter(
    (route) => route.world === "Makuri Islands"
  ).length;
  const totalSc = initialCyclingRoutes.filter(
    (route) => route.world === "Scotland"
  ).length;

  // THIS STILL HAS TO BE UPDATED MANUALLY WHEN NEW ROUTES ARE ADDED -
  // I might automate that in the future when I find the time

  const resultWa =
    routeids.filter(inRange, [1, 30]).length +
    routeids.filter(inRange, [88, 91]).length +
    routeids.filter(inRange, [113, 114]).length +
    routeids.filter(inRange, [119, 123]).length +
    routeids.filter(inRange, [135, 136]).length +
    routeids.filter(inRange, [142, 149]).length + 
    routeids.filter(inRange, [150, 151]).length + 
    routeids.filter(inRange, [153, 169]).length +
    routeids.filter(inRange, [174, 177]).length + 
    routeids.filter(inRange, [183, 184]).length;
  const resultLo = routeids.filter(inRange, [31, 43]).length +
    routeids.filter(inRange, [171, 171]).length;
  const resultNe = 
    routeids.filter(inRange, [44, 54]).length +
    routeids.filter(inRange, [133, 133]).length +
    routeids.filter(inRange, [172, 172]).length;
  const resultIn = routeids.filter(inRange, [55, 59]).length;
  const resultYo = routeids.filter(inRange, [60, 64]).length +
    routeids.filter(inRange, [170, 170]).length +
    routeids.filter(inRange, [179, 179]).length;
  const resultRi = 
    routeids.filter(inRange, [65, 67]).length +
    routeids.filter(inRange, [134, 134]).length +
    routeids.filter(inRange, [173, 173]).length +
    routeids.filter(inRange, [178, 178]).length;
  const resultFr = 
    routeids.filter(inRange, [68, 74]).length +
    routeids.filter(inRange, [132, 132]).length;
  const resultPa = routeids.filter(inRange, [75, 76]).length;
  const resultMa =
    routeids.filter(inRange, [92, 112]).length +
    routeids.filter(inRange, [115, 118]).length + 
    routeids.filter(inRange, [124, 131]).length + 
    routeids.filter(inRange, [152,152]).length +
    routeids.filter(inRange, [180,181]).length;
   const resultSc = 
    routeids.filter(inRange, [137, 141]).length +
    routeids.filter(inRange, [182, 182]).length;

  // total achievement percentage
  const achievmentpercent = Math.round((routeidsCycling.length * 100) / (totalWa + totalLo + totalNe + totalIn + totalYo + totalRi + totalFr + totalPa + totalMa + totalSc));

  // percentages of Worlds
  const achievmentpercentWa = Math.round((resultWa * 100) / totalWa);
  const achievmentpercentLo = Math.round((resultLo * 100) / totalLo);
  const achievmentpercentNe = Math.round((resultNe * 100) / totalNe);
  const achievmentpercentIn = Math.round((resultIn * 100) / totalIn);
  const achievmentpercentYo = Math.round((resultYo * 100) / totalYo);
  const achievmentpercentRi = Math.round((resultRi * 100) / totalRi);
  const achievmentpercentFr = Math.round((resultFr * 100) / totalFr);
  const achievmentpercentPa = Math.round((resultPa * 100) / totalPa);
  const achievmentpercentMa = Math.round((resultMa * 100) / totalMa);
  const achievmentpercentSc = Math.round((resultSc * 100) / totalSc);

  // get sums out of initialCyclingRoutes
  const totaldistance = Math.round(
    initialCyclingRoutes.reduce((acc, route) => acc + route.distance, 0)
  );
  const achieveddistance =
    achievedrouteidsCycling[0] === 0
      ? 0
      : Math.round(
          achievedrouteidsCycling
            .map(
              (routeid) =>
                initialRoutes.find((x) => x.routeid === routeid).distance
            )
            .reduce((a, b) => a + b)
        );
  const totalelevation = Math.round(
    initialCyclingRoutes.reduce((acc, route) => acc + route.elevation, 0)
  );
  const achievedelevation =
    achievedrouteidsCycling[0] === 0
      ? 0
      : Math.round(
          achievedrouteidsCycling
            .map(
              (routeid) =>
                initialRoutes.find((x) => x.routeid === routeid).elevation
            )
            .reduce((a, b) => a + b)
        );
  const totalexp = Math.round(
    initialCyclingRoutes.reduce((acc, route) => acc + route.exp, 0)
  );
  const achievedexp =
    achievedrouteidsCycling[0] === 0
      ? 0
      : Math.round(
          achievedrouteidsCycling
            .map(
              (routeid) => initialRoutes.find((x) => x.routeid === routeid).exp
            )
            .reduce((a, b) => a + b)
        );

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.headline}>
          <Typography variant="subtitle1" align="center">
            <strong>OVERALL PROGRESS</strong>
          </Typography>
        </CardContent>
        <CardContent className={classes.overviewcontainer}>
          <div>
            <Typography className={classes.textoverview} align="center">
              {achievmentpercent}%
            </Typography>
            <Avatar style={{ backgroundColor: "transparent" }}>
              <CircularProgress
                className={classes.progressIcon}
                size={35}
                style={{ color: "#d3d3d3" }}
                variant="static"
                value={100}
              />
              <CircularProgress
                className={classes.progressIcon}
                size={35}
                color="secondary"
                variant="static"
                value={achievmentpercent}
              />

              <EmojiEventsIcon
                className={classes.progressIcon}
                style={{ color: "#fc6719" }}
              />
            </Avatar>
            <Typography className={classes.textsubheader} align="center">
              #
            </Typography>
            <Typography className={classes.textsubheader} align="center">
              {routeidsCycling.length === 0 ? 0 : routeidsCycling.length}
            </Typography>
            <Divider />
            <Typography className={classes.textsubheader} align="center">
              {initialCyclingRoutes.length}
            </Typography>
          </div>
          <div>
            <Typography className={classes.textoverview} align="center">
              {Math.round((achieveddistance / totaldistance) * 100)}%
            </Typography>
            <Avatar style={{ backgroundColor: "transparent" }}>
              <CircularProgress
                className={classes.progressIcon}
                size={35}
                style={{ color: "#d3d3d3" }}
                variant="static"
                value={100}
              />
              <CircularProgress
                className={classes.progressIcon}
                size={35}
                color="secondary"
                variant="static"
                value={Math.round((achieveddistance / totaldistance) * 100)}
              />

              <DirectionsBikeIcon
                className={classes.progressIcon}
                style={{ color: "#fc6719" }}
              />
            </Avatar>
            <Typography className={classes.textsubheader} align="center">
              {displaymiles ? "mi" : "km"}
            </Typography>
            <Typography className={classes.textsubheader} align="center">
              {displaymiles
                ? Math.round(achieveddistance * 0.621371)
                : achieveddistance}
            </Typography>
            <Divider />
            <Typography className={classes.textsubheader} align="center">
              {displaymiles
                ? Math.round(totaldistance * 0.621371)
                : totaldistance}
            </Typography>
          </div>
          <div>
            <Typography className={classes.textoverview} align="center">
              {Math.round((achievedelevation / totalelevation) * 100)}%
            </Typography>
            <Avatar style={{ backgroundColor: "transparent" }}>
              <CircularProgress
                className={classes.progressIcon}
                size={35}
                style={{ color: "#d3d3d3" }}
                variant="static"
                value={100}
              />
              <CircularProgress
                className={classes.progressIcon}
                size={35}
                color="secondary"
                variant="static"
                value={Math.round((achievedelevation / totalelevation) * 100)}
              />

              <LandscapeIcon
                className={classes.progressIcon}
                style={{ color: "#fc6719" }}
              />
            </Avatar>
            <Typography className={classes.textsubheader} align="center">
              {displaymiles ? "ft" : "m"}
            </Typography>
            <Typography className={classes.textsubheader} align="center">
              {displaymiles
                ? Math.round(achievedelevation * 3.28084)
                : achievedelevation}
            </Typography>
            <Divider />
            <Typography className={classes.textsubheader} align="center">
              {displaymiles
                ? Math.round(totalelevation * 3.28084)
                : totalelevation}
            </Typography>
          </div>
          <div>
            <Typography className={classes.textoverview} align="center">
              {Math.round((achievedexp / totalexp) * 100)}%
            </Typography>
            <Avatar
              className={classes.progressIcon}
              style={{ color: "#fc6719", backgroundColor: "#fff" }}
            >
              XP
            </Avatar>
            <Avatar style={{ backgroundColor: "transparent" }}>
              <CircularProgress
                className={classes.progressIcon}
                size={35}
                style={{ color: "#d3d3d3" }}
                variant="static"
                value={100}
              />
              <CircularProgress
                className={classes.progressIcon}
                size={35}
                color="secondary"
                variant="static"
                value={Math.round((achievedexp / totalexp) * 100)}
              />
            </Avatar>
            <Typography className={classes.textsubheader} align="center">
              XP
            </Typography>
            <Typography className={classes.textsubheader} align="center">
              {achievedexp}
            </Typography>
            <Divider />
            <Typography className={classes.textsubheader} align="center">
              {totalexp}
            </Typography>
          </div>
          <div>
            <Typography className={classes.textoverview} align="center">
              {Math.round((resultbadgescycling * 100) / 39)}%
            </Typography>
            <Avatar style={{ backgroundColor: "transparent" }}>
              <CircularProgress
                className={classes.progressIcon}
                size={35}
                style={{ color: "#d3d3d3" }}
                variant="static"
                value={100}
              />
              <CircularProgress
                className={classes.progressIcon}
                size={35}
                color="secondary"
                variant="static"
                value={Math.round((resultbadgescycling * 100) / 39)}
              />

              <StarIcon
                className={classes.progressIcon}
                style={{ color: "#fc6719" }}
              />
            </Avatar>
            <Typography className={classes.textsubheader} align="center">
              #
            </Typography>
            <Typography className={classes.textsubheader} align="center">
              {resultbadgescycling}
            </Typography>
            <Divider />
            <Typography className={classes.textsubheader} align="center">
              39
            </Typography>
          </div>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent className={classes.headline}>
          <Typography variant="subtitle1" align="center">
            <strong>ROUTE ACHIEVEMENTS</strong>
          </Typography>
        </CardContent>
        <CardHeader
          className={classes.cardheader}
          avatar={
            <div className={classes.iconcontainer}>
              <Avatar
                style={{ backgroundColor: "#fc6719", fontSize: "17px" }}
                className={classes.avatar}
              >
                Wa
              </Avatar>
              <ProgressionIconTo achievmentpercent={achievmentpercentWa} />
            </div>
          }
          title="Watopia"
          subheader={`${achievmentpercentWa}% (${resultWa}/${totalWa})`}
        />
        <CardHeader
          className={classes.cardheader}
          avatar={
            <div className={classes.iconcontainer}>
              <Avatar
                style={{ backgroundColor: "#0093d1", fontSize: "17px" }}
                className={classes.avatar}
              >
                Lo
              </Avatar>
              <ProgressionIconTo achievmentpercent={achievmentpercentLo} />
            </div>
          }
          title="London"
          subheader={`${achievmentpercentLo}% (${resultLo}/${totalLo})`}
        />

        <CardHeader
          className={classes.cardheader}
          avatar={
            <div className={classes.iconcontainer}>
              <Avatar
                style={{ backgroundColor: "#e62a8b", fontSize: "17px" }}
                className={classes.avatar}
              >
                NY
              </Avatar>
              <ProgressionIconTo achievmentpercent={achievmentpercentNe} />
            </div>
          }
          title="New York"
          subheader={`${achievmentpercentNe}% (${resultNe}/${totalNe})`}
        />

        <CardHeader
          className={classes.cardheader}
          avatar={
            <div className={classes.iconcontainer}>
              <Avatar
                style={{ backgroundColor: "#3fc257", fontSize: "17px" }}
                className={classes.avatar}
              >
                In
              </Avatar>
              <ProgressionIconTo achievmentpercent={achievmentpercentIn} />
            </div>
          }
          title="Innsbruck"
          subheader={`${achievmentpercentIn}% (${resultIn}/${totalIn})`}
        />

        <CardHeader
          className={classes.cardheader}
          avatar={
            <div className={classes.iconcontainer}>
              <Avatar
                style={{ backgroundColor: "#f4c117", fontSize: "17px" }}
                className={classes.avatar}
              >
                Yo
              </Avatar>
              <ProgressionIconTo achievmentpercent={achievmentpercentYo} />
            </div>
          }
          title="Yorkshire"
          subheader={`${achievmentpercentYo}% (${resultYo}/${totalYo})`}
        />

        <CardHeader
          className={classes.cardheader}
          avatar={
            <div className={classes.iconcontainer}>
              <Avatar
                style={{ backgroundColor: "#757575", fontSize: "17px" }}
                className={classes.avatar}
              >
                Ri
              </Avatar>
              <ProgressionIconTo achievmentpercent={achievmentpercentRi} />
            </div>
          }
          title="Richmond"
          subheader={`${achievmentpercentRi}% (${resultRi}/${totalRi})`}
        />
        <CardHeader
          className={classes.cardheader}
          avatar={
            <div className={classes.iconcontainer}>
              <Avatar
                style={{ backgroundColor: "#0055a4", fontSize: "17px" }}
                className={classes.avatar}
              >
                Fr
              </Avatar>
              <ProgressionIconTo achievmentpercent={achievmentpercentFr} />
            </div>
          }
          title="France"
          subheader={`${achievmentpercentFr}% (${resultFr}/${totalFr})`}
        />
        <CardHeader
          className={classes.cardheader}
          avatar={
            <div className={classes.iconcontainer}>
              <Avatar
                style={{ backgroundColor: "#ef4135", fontSize: "17px" }}
                className={classes.avatar}
              >
                Pa
              </Avatar>
              <ProgressionIconTo achievmentpercent={achievmentpercentPa} />
            </div>
          }
          title="Paris"
          subheader={`${achievmentpercentPa}% (${resultPa}/${totalPa})`}
        />
        <CardHeader
          className={classes.cardheader}
          avatar={
            <div className={classes.iconcontainer}>
              <Avatar
                style={{ backgroundColor: "#004f00", fontSize: "17px" }}
                className={classes.avatar}
              >
                MI
              </Avatar>
              <ProgressionIconTo achievmentpercent={achievmentpercentMa} />
            </div>
          }
          title="Makuri Islands"
          subheader={`${achievmentpercentMa}% (${resultMa}/${totalMa})`}
        />
        <CardHeader
          className={classes.cardheader}
          avatar={
            <div className={classes.iconcontainer}>
              <Avatar
                style={{ backgroundColor: "#64c4ed", fontSize: "17px" }}
                className={classes.avatar}
              >
                Sc
              </Avatar>
              <ProgressionIconTo achievmentpercent={achievmentpercentSc} />
            </div>
          }
          title="Scotland"
          subheader={`${achievmentpercentSc}% (${resultSc}/${totalSc})`}
        />
      </Card>
      <AuthCheck
        fallback={
          <Typography
            align="center"
            color="primary"
            className={classes.justdemo}
          >
            This is just an demo of the achievement tracking. Sign in to track
            your own achievements.
          </Typography>
        }
      >
        {" "}
      </AuthCheck>
    </div>
  );
}

export default memo(AchievementDrawer);