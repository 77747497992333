import { useState, useEffect } from 'react';
import routedata from '../data/routedata';
import filterArray from '../hooks/filterArray';
import { useFirestoreDocData, useFirestore, useUser } from 'reactfire';

//Import Routedata
const initialRoutes = routedata.sort(function(a, b) {
	if (a.route < b.route) {
		return -1;
	}
	if (a.route > b.route) {
		return 1;
	}
	return 0;
});

//Firebase Stuff

export default () => {
	//FIREBASE STUFF
	const user = useUser();

	const firestore = user === null ? null : useFirestore();

	const [ docRef ] = useState(user === null ? null : firestore.collection('users').doc(user.uid));

	//check if user visits first time, if so, create an empty document for his uid
	const usercheck =
		user === null
			? null
			: useEffect(
					() => {
						docRef.get().then((doc) => {
							if (doc.exists) {
								// console.log('User exists');
							} else {
								docRef.set({}); // create the document
								// console.log('created new user');
							}
						});
					},
					[ docRef ]
				);

	//create listener for userachievements
	const achievementImport = user === null ? null : useFirestoreDocData(docRef);

	// user achievements with exampleset for guests
	const userachievements =
		user === null
			? [
					'RouteAch1',
					'RouteAch2',
					'RouteAch7',
					'RouteAch10',
					'RouteAch17',
					'RouteAch20',
					'RouteAch23',
					'RouteAch24',
					'RouteAch26',
					'RouteAch29',
					'RouteAch32',
					'RouteAch34',
					'RouteAch35',
					'RouteAch36',
					'RouteAch40',
					'RouteAch41',
					'RouteAch42',
					'RouteAch45',
					'RouteAch48',
					'RouteAch49',
					'RouteAch50',
					'RouteAch60',
					'RouteAch61',
					'RouteAch62',
					'RouteAch65',
					'RouteAch66',
					'RouteAch67',
					'RouteAch70',
					'RouteAch76',
					'RouteAch80',
					'RouteAch82',
					'RouteAch83',
					'RouteAch84',
					'RouteAch85',
					'RouteAch89',
					'RouteAch92',
					'RouteAch99'
				]
			: Object.keys(achievementImport).filter((e) => achievementImport[e] === true);

	const achievedrouteids =
		userachievements.length === 0 ? [ 0 ] : userachievements.map((el) => parseInt(el.slice(8)));

	const allrouteids = [ ...Array((initialRoutes.length + 1)).keys() ].slice(1);
	const notachievedrouteids = allrouteids.filter((x) => !achievedrouteids.includes(x));

	// Badges Handling
	const userbadges =
		user === null
			? [
					'Badge1',
					'Badge4',
					'Badge9',
					'Badge10',
					'Badge13',
					'Badge17',
					'Badge20',
					'Badge23',
					'Badge24',
					'Badge25',
					'Badge32',
					'Badge40',
					'Badge41',
					'Badge45',
					'Badge48'
				]
			: Object.keys(achievementImport).filter((e) => achievementImport[e] === 'done');

	//Separation run and cycling badges (totals)
	const userbadgesids = userbadges.map((el) => el.slice(5));

	// partial of the different Badges
	function inRange(x) {
		return this[0] <= x && x <= this[1];
	}

	// number of badges filtered by sport
	const resultbadgescycling = userbadgesids.filter(inRange, [ 1, 39 ]).length;
	const resultbadgesrunning = userbadgesids.filter(inRange, [ 40, 52 ]).length;

	//convert to miles true = show miles
	const displaymiles = user === null ? false : achievementImport.unitdisplay === 'miles' ? true : false;

	//hide XP and hide leadin
	const hidexp = user === null ? false : achievementImport.xpdisplay === 'hide' ? true : false;
	const hideleadin = user === null ? false : achievementImport.leadindisplay === 'hide' ? true : false;

	//select running or cycling
	const initchosensport =
		user === null ? 'cycling' : achievementImport.sportselection === 'running' ? 'running' : 'cycling';

	//End Firebase Stuff

	//Filter Values
	const [ chosenworld, setchosenworld ] = useState([
		'Watopia',
		'London',
		'New York',
		'Innsbruck',
		'Yorkshire',
		'Richmond',
		'France',
		'Paris',
		'Makuri Islands'
	]);
	const [ chosendistance, setchosendistance ] = useState([ 0, 174 ]); //before running:  useState([ 3, 174 ]);
	const [ chosenelevation, setchosenelevation ] = useState([ 0, 2623 ]); // useState([ 19, 2623 ]);
	const [ chosenkom, setchosenkom ] = useState([]);
	const [ chosensprint, setchosensprint ] = useState([]);
	const [ chosenlap, setchosenlap ] = useState([]);
	const [ filterachievements, setfilterachievments ] = useState([]);
	const [ chosensport, setchosensport ] = useState(initchosensport);

	//check if initial mount
	// const isInitialMount = useRef(true);

	//Sorting Process
	const [ sortedroutes, setsortedroutes ] = useState(initialRoutes);
	const [ sortoption, setsortoption ] = useState('AZ');

	//Filter Process
	const [ filtered, setfiltered ] = useState(sortedroutes);

	/// Search
	const [ searchword, setsearchword ] = useState('');

	useEffect(
		() => {
			// if (isInitialMount.current) {
			// 	isInitialMount.current = false;
			// } else {
			// kom check if array is empty to show all
			const komempty = chosenkom.length === 0 ? true : false;
			const sprintempty = chosensprint.length === 0 ? true : false;
			const lapempty = chosenlap.length === 0 ? true : false;
			const filterachievementsempty = filterachievements.length === 0 ? true : false;

			//check if search is empty
			// const searchempty = searchword.length === 0 ? true : false;

			//filters
			const filters = {
				world: (world) => chosenworld.includes(world),
				distance: (distance) => distance >= chosendistance[0] && distance <= chosendistance[1],
				elevation: (elevation) => elevation >= chosenelevation[0] && elevation <= chosenelevation[1],
				kom: (kom) => (komempty ? kom : kom.find((x) => chosenkom.every((x) => kom.includes(x)))),
				sprint: (sprint) =>
					sprintempty ? sprint : sprint.find((x) => chosensprint.every((x) => sprint.includes(x))),
				lap: (lap) => (lapempty ? lap : lap.find((x) => chosenlap.every((x) => lap.includes(x)))),
				routeid: (routeid) => (filterachievementsempty ? routeid : filterachievements.includes(routeid)),
				route: (route) => route.toLowerCase().includes(searchword.toLowerCase()),
				sport: (sport) => chosensport.includes(sport)
			};
			setfiltered(filterArray(sortedroutes, filters));
			// console.log('useEffect ran');
			// }
		},
		[
			chosenworld,
			chosendistance,
			chosenelevation,
			chosenkom,
			chosensprint,
			chosenlap,
			sortoption,
			searchword,
			filterachievements,
			chosensport,
			sortedroutes
		]
	);

	//steering the achievementbutton in the nav menu
	const [ activeachievementbutton, setactiveachievementbutton ] = useState('all');

	//sorting functions

	const alphaUp = () => {
		setsortedroutes(
			initialRoutes.sort(function(a, b) {
				if (a.route < b.route) {
					return -1;
				}
				if (a.route > b.route) {
					return 1;
				}
				return 0;
			})
		);
		setsortoption('AZ');
	};

	const alphaDown = () => {
		setsortedroutes(
			initialRoutes.sort(function(a, b) {
				if (b.route < a.route) {
					return -1;
				}
				if (b.route > a.route) {
					return 1;
				}
				return 0;
			})
		);
		setsortoption('ZA');
	};
	const distUp = () => {
		setsortedroutes(
			initialRoutes.sort(function(a, b) {
				if (a.distance < b.distance) {
					return -1;
				}
				if (a.distance > b.distance) {
					return 1;
				}
				return 0;
			})
		);
		setsortoption('D19');
	};
	const distDown = () => {
		setsortedroutes(
			initialRoutes.sort(function(a, b) {
				if (b.distance < a.distance) {
					return -1;
				}
				if (b.distance > a.distance) {
					return 1;
				}
				return 0;
			})
		);
		setsortoption('D91');
	};
	const elevUp = () => {
		setsortedroutes(
			initialRoutes.sort(function(a, b) {
				if (a.elevation < b.elevation) {
					return -1;
				}
				if (a.elevation > b.elevation) {
					return 1;
				}
				return 0;
			})
		);
		setsortoption('E19');
	};
	const elevDown = () => {
		setsortedroutes(
			initialRoutes.sort(function(a, b) {
				if (b.elevation < a.elevation) {
					return -1;
				}
				if (b.elevation > a.elevation) {
					return 1;
				}
				return 0;
			})
		);
		setsortoption('E91');
	};
	const gradUp = () => {
		setsortedroutes(
			initialRoutes.sort(function(a, b) {
				if (a.elevation / a.distance < b.elevation / b.distance) {
					return -1;
				}
				if (a.elevation / a.distance > b.elevation / b.distance) {
					return 1;
				}
				return 0;
			})
		);
		setsortoption('G19');
	};
	const gradDown = () => {
		setsortedroutes(
			initialRoutes.sort(function(a, b) {
				if (b.elevation / b.distance < a.elevation / a.distance) {
					return -1;
				}
				if (b.elevation / b.distance > a.elevation / a.distance) {
					return 1;
				}
				return 0;
			})
		);
		setsortoption('G91');
	};

	// alphaUp();

	return {
		initialRoutes,
		userbadges,
		docRef,
		displaymiles,
		hidexp,
		hideleadin,
		usercheck, //(just to get rid of the eslint not used)
		filtered,
		alphaUp,
		alphaDown,
		distUp,
		distDown,
		elevUp,
		elevDown,
		gradUp,
		gradDown,
		searchword,
		setsearchword,
		chosenworld,
		setchosenworld,
		chosendistance,
		setchosendistance,
		chosenelevation,
		setchosenelevation,
		chosenkom,
		setchosenkom,
		chosensprint,
		setchosensprint,
		chosenlap,
		setchosenlap,
		userachievements,
		setfilterachievments,
		achievedrouteids,
		notachievedrouteids,
		activeachievementbutton,
		setactiveachievementbutton,
		toggleWorld: (WorldId) => {
			chosenworld.includes(WorldId)
				? setchosenworld(chosenworld.filter((world) => world !== WorldId))
				: setchosenworld(chosenworld.concat(WorldId));
		},
		toggleKom: (KomId) => {
			chosenkom.includes(KomId)
				? setchosenkom(chosenkom.filter((kom) => kom !== KomId))
				: setchosenkom(chosenkom.concat(KomId));
		},
		toggleSprint: (SprintId) => {
			chosensprint.includes(SprintId)
				? setchosensprint(chosensprint.filter((sprint) => sprint !== SprintId))
				: setchosensprint(chosensprint.concat(SprintId));
		},
		toggleLap: (LapId) => {
			chosenlap.includes(LapId)
				? setchosenlap(chosenlap.filter((lap) => lap !== LapId))
				: setchosenlap(chosenlap.concat(LapId));
		},
		chosensport,
		setchosensport,
		resultbadgescycling,
		resultbadgesrunning,
		user
	};
};
