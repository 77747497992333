import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

export default function Donate(props) {
	const handleCloseDonate = () => {
		props.setOpenDonate(false);
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(
		() => {
			if (props.openDonate) {
				const { current: descriptionElement } = descriptionElementRef;
				if (descriptionElement !== null) {
					descriptionElement.focus();
				}
			}
		},
		[ props.openDonate ]
	);

	return (
		<Dialog
			open={props.openDonate}
			onClose={handleCloseDonate}
			maxWidth="md"
			scroll="paper"
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle id="scroll-dialog-title">Donate</DialogTitle>
			<DialogContent dividers={true}>
				<Typography
					style={{ color: 'rgba(0, 0, 0, 0.54)' }}
					component={'span'}
					id="scroll-dialog-description"
					ref={descriptionElementRef}
					tabIndex={-1}
				>
					<p>If you enjoy using ZwiftHub, maybe you would like to support it?</p>
					<p>
						ZwiftHub is and always will be free to use. Unfortunately, the infrastructure to run ZwiftHub
						(Hosting & CDN Servers, Database, Domain, etc.) is not free. With the daily increasing user
						numbers, it is getting costlier for me. If you want to help me out and participate in the costs,
						a donation is much appreciated.
					</p>
					<p>
						Additionally, please keep in mind that ZwiftHub is a hobby project. I maintain it, create new
						features and give e-mail support in my free time while you are cycling or running on Zwift.
						Donations help to keep me motivated to work on new features with passion.
					</p>

					<p>Thank you upfront for your support!</p>

					<form
						action="https://www.paypal.com/donate"
						method="post"
						target="_blank"
						rel="noopener noreferrer"
					>
						<input type="hidden" name="hosted_button_id" value="CZZLGURE8GMB2" />
						<input
							type="image"
							src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
							border="0"
							name="submit"
							title="PayPal - The safer, easier way to pay online!"
							alt="Donate with PayPal button"
						/>
						<img
							alt=""
							border="0"
							src="https://www.paypal.com/en_US/i/scr/pixel.gif"
							width="1"
							height="1"
						/>
					</form>

					{/* <Button
						variant="contained"
						color="primary"
						href="https://www.paypal.me/TobyZwiftHub/10usd"
						target="_blank"
						rel="noopener noreferrer"
					>
						Donate with paypal
					</Button> */}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseDonate} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}
