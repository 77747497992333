import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { WorldFilterContext } from '../../contexts/WorldFilter.context';
import useToggle from '../../hooks/useToggleState';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},

	sprintchips: {
		padding: '0px 5px 5px',
		display: 'flex',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '120px',
		maxWidth: '120px'
	}
}));

function SprintYorkshireFilter() {
	const classes = useStyles();

	// Import from context
	const { toggleSprint, chosensprint } = useContext(WorldFilterContext);

	// Definition of the toggle states for the sprints

	const [ isYorkshireSprint, toggleIsYorkshireSprint ] = useToggle(chosensprint.includes('Yorkshire Sprint'));
	const [ isYorkshireSprintRev, toggleIsYorkshireSprintRev ] = useToggle(
		chosensprint.includes('Yorkshire Sprint Rev.')
	);

	return (
		<div className={classes.root}>
			<div className={classes.sprintchips}>
				<Chip
					className={classes.chip}
					label="Yorkshire Sprint"
					onClick={() => {
						toggleSprint('Yorkshire Sprint');
						toggleIsYorkshireSprint();
					}}
					variant={isYorkshireSprint ? 'default' : 'outlined'}
					color={isYorkshireSprint ? 'primary' : 'default'}
					style={{ color: isYorkshireSprint ? '' : '#b4b4b4' }}
					size="small"
				/>
				<Chip
					className={classes.chip}
					label="Yorks. Sprint Rev."
					onClick={() => {
						toggleSprint('Yorkshire Sprint Rev.');
						toggleIsYorkshireSprintRev();
					}}
					variant={isYorkshireSprintRev ? 'default' : 'outlined'}
					color={isYorkshireSprintRev ? 'primary' : 'default'}
					style={{ color: isYorkshireSprintRev ? '' : '#b4b4b4' }}
					size="small"
				/>
			</div>
		</div>
	);
}
export default memo(SprintYorkshireFilter);
