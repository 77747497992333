import React, { useContext, useState, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { WorldFilterContext } from '../contexts/WorldFilter.context';
import Slider from '@material-ui/core/Slider';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},
	container: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap'
	},
	slider: {
		width: '200px'
	},
	chip: {
		margin: theme.spacing(0.3),
		minWidth: '100px'
	}
}));

function ElevationFilter() {
	const classes = useStyles();
	const { chosenelevation, setchosenelevation, displaymiles } = useContext(WorldFilterContext);
	const [ value, setValue ] = useState(chosenelevation);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const handleChangeCommitted = (event, newValue) => {
		setchosenelevation(newValue);
	};

	//for change to feet
	function valueLabelFormat(value) {
		if (displaymiles === true) {
			return Math.round(value * 3.28084);
		} else {
			return value;
		}
	}

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<div>
					<Slider
						className={classes.slider}
						value={value}
						onChange={handleChange}
						onChangeCommitted={handleChangeCommitted}
						valueLabelDisplay="auto"
						valueLabelFormat={valueLabelFormat}
						aria-labelledby="range-slider"
						max={2623}
						min={0}
					/>
				</div>
				<div>
					<Chip
						className={classes.chip}
						onClick={() => {
							setValue([ 0, 2623 ]);
							setchosenelevation([ 0, 2623 ]);
						}}
						size="small"
						label="reset"
						variant="outlined"
						color="secondary"
					/>
				</div>
			</div>
		</div>
	);
}
export default memo(ElevationFilter);
