import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import ProgressionIcon from './ProgressionIcon';
import ProgressionIconRun from './ProgressionIconRun';
import BadgeIcon from './BadgeIcon';
import BadgeIconRun from './BadgeIconRun';
import BadgeDialog from './BadgeDialog';
import BadgeDialogRun from './BadgeDialogRun';
import { WorldFilterContext } from '../contexts/WorldFilter.context';

const useStyles = makeStyles({
	root: {
		width: '100%',
		position: 'fixed',
		bottom: 0,
		display: 'flex',
		alignItems: 'flex-end',
		paddingBottom: -2,
		paddingRight: 15,
		paddingLeft: 15,
		zIndex: 5
	},

	bottomnavact: {
		paddingBottom: 2,
		color: 'white'
	}
});

export default function BottomNav(props) {
	const classes = useStyles();

	//context import
	const { chosensport } = useContext(WorldFilterContext);

	return (
		<React.Fragment>
			{chosensport === 'cycling' ? (
				<BottomNavigation style={{ backgroundColor: '#fc6719' }} showLabels className={classes.root}>
					<BottomNavigationAction
						className={classes.bottomnavact}
						label="Filter"
						onClick={props.handleDrawerToggle}
						icon={<FilterListIcon fontSize="large" style={{ color: 'white' }} />}
					/>
					<BottomNavigationAction
						className={classes.bottomnavact}
						label="Search"
						onClick={props.handleDrawerToggleSearch}
						icon={<SearchIcon fontSize="large" style={{ color: 'white' }} />}
					/>

					<BottomNavigationAction
						className={classes.bottomnavact}
						label="Badges"
						onClick={props.handleClickOpenBadgeDialog}
						icon={<BadgeIcon />}
					/>
					<BadgeDialog
						openBadgeDialog={props.openBadgeDialog}
						setOpenBadgeDialog={props.setOpenBadgeDialog}
						handleClickCloseBadgeDialog={props.handleClickCloseBadgeDialog}
					/>
					<BadgeDialogRun
						openBadgeDialogRun={props.openBadgeDialogRun}
						setOpenBadgeDialogRun={props.setOpenBadgeDialogRun}
						handleClickCloseBadgeDialogRun={props.handleClickCloseBadgeDialogRun}
					/>

					<BottomNavigationAction
						className={classes.bottomnavact}
						label="Achievements"
						onClick={props.handleAchievementDrawerToggle}
						icon={<ProgressionIcon />}
					/>
				</BottomNavigation>
			) : (
				<BottomNavigation style={{ backgroundColor: '#0093d1' }} showLabels className={classes.root}>
					<BottomNavigationAction
						className={classes.bottomnavact}
						label="Filter"
						onClick={props.handleDrawerToggle}
						icon={<FilterListIcon fontSize="large" style={{ color: 'white' }} />}
					/>
					<BottomNavigationAction
						className={classes.bottomnavact}
						label="Search"
						onClick={props.handleDrawerToggleSearch}
						icon={<SearchIcon fontSize="large" style={{ color: 'white' }} />}
					/>

					<BottomNavigationAction
						className={classes.bottomnavact}
						label="Badges"
						onClick={props.handleClickOpenBadgeDialogRun}
						icon={<BadgeIconRun />}
					/>

					<BadgeDialogRun
						openBadgeDialogRun={props.openBadgeDialogRun}
						setOpenBadgeDialogRun={props.setOpenBadgeDialogRun}
						handleClickCloseBadgeDialogRun={props.handleClickCloseBadgeDialogRun}
					/>

					<BottomNavigationAction
						className={classes.bottomnavact}
						label="Achievements"
						onClick={props.handleAchievementDrawerToggleRun}
						icon={<ProgressionIconRun />}
					/>
				</BottomNavigation>
			)}
		</React.Fragment>
	);
}
