import React, { memo, useContext, useState } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import zwifthub_logo from '../media/zwifthub_logo.png';
import Hidden from '@material-ui/core/Hidden';
import ProgressionIcon from './ProgressionIcon';
import BadgeIcon from './BadgeIcon';
import BadgeIconRun from './BadgeIconRun';
import Drawer from '@material-ui/core/Drawer';
import CancelIcon from '@material-ui/icons/Cancel';
import { WorldFilterContext } from '../contexts/WorldFilter.context';
import InputAdornment from '@material-ui/core/InputAdornment';
import AchievementDrawer from './AchievementDrawer';
import Dialog from '@material-ui/core/Dialog';
import { AuthCheck, useUser } from 'reactfire';
import LogInOut from './LogInOut';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import About from './MoreFolder/About';
import News from './MoreFolder/News';
import Contact from './MoreFolder/Contact';
import Donate from './MoreFolder/Donate';
import PrivacyPolicy from './MoreFolder/PrivacyPolicy';
import ToSCopyright from './MoreFolder/ToSCopyright';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Help from './Help';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import BadgeDialog from './BadgeDialog';
import BadgeDialogRun from './BadgeDialogRun';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AchievementDrawerRun from './AchievementDrawerRun';
import ProgressionIconRun from './ProgressionIconRun';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	appBar: {
		[theme.breakpoints.up('md')]: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth
			// zIndex: theme.zIndex.drawer + 1
		}
	},
	button: {
		margin: theme.spacing(1)
	},

	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25)
		},

		width: '100%',
		[theme.breakpoints.up('sm')]: {
			// marginLeft: theme.spacing(1),
			width: 'auto',
			marginLeft: -8
		}
	},
	searchIcon: {
		width: theme.spacing(7),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	inputRoot: {
		color: 'inherit'
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 150,
			'&:focus': {
				width: 250
			}
		}
	},

	menuButton: {
		marginRight: theme.spacing(2)
	},
	title: {
		flexGrow: 1
	},

	achievementdrawer: {
		width: { drawerWidth }
	},
	small: {
		width: theme.spacing(4),
		height: theme.spacing(4)
	},
	avatar: {
		width: theme.spacing(4),
		height: theme.spacing(4),
		color: '#0093d1',
		backgroundColor: '#fff'
	}
}));

function TopBar(props) {
	const classes = useStyles();

	const { searchword, setsearchword, chosensport } = useContext(WorldFilterContext);

	const user = useUser();

	const handleChange = (e) => {
		setsearchword(e.target.value);
	};

	const handleClearSearch = (e) => {
		setsearchword('');
	};
	//	handling AuthButton

	const [ open, setOpen ] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
	};

	// handling of help menu

	const [ openHelp, setOpenHelp ] = useState(false);

	const handleClickOpenHelp = () => {
		setOpenHelp(true);
	};

	const handleClickCloseHelp = () => {
		setOpenHelp(false);
	};

	//handling of more menu
	const [ anchorElmore, setAnchorElmore ] = useState(null);

	const handleClickmore = (event) => {
		setAnchorElmore(event.currentTarget);
	};

	const handleClosemore = () => {
		setAnchorElmore(null);
	};

	//statemanagement for more menu
	const [ openAbout, setOpenAbout ] = useState(false);

	const handleClickOpenAbout = () => {
		setOpenAbout(true);
		setAnchorElmore(null);
	};

	const [ openNews, setOpenNews ] = useState(false);

	const handleClickOpenNews = () => {
		setOpenNews(true);
		setAnchorElmore(null);
	};

	const [ openContact, setOpenContact ] = useState(false);

	const handleClickOpenContact = () => {
		setOpenContact(true);
		setAnchorElmore(null);
	};

	const [ openDonate, setOpenDonate ] = useState(false);

	const handleClickOpenDonate = () => {
		setOpenDonate(true);
		setAnchorElmore(null);
	};

	const [ openPrivacyPolicy, setOpenPrivacyPolicy ] = useState(false);

	const handleClickOpenPrivacyPolicy = () => {
		setOpenPrivacyPolicy(true);
		setAnchorElmore(null);
	};

	const [ openToSCopyright, setOpenToSCopyright ] = useState(false);

	const handleClickOpenToSCopyright = () => {
		setOpenToSCopyright(true);
		setAnchorElmore(null);
	};

	// trigger for serach delete entries button

	const searchempty = searchword === '';

	return (
		<div className={classes.root}>
			<AppBar
				color={chosensport === 'cycling' ? 'primary' : 'secondary'}
				position="fixed"
				className={classes.appBar}
			>
				<Toolbar>
					<Hidden mdUp>
						<img src={zwifthub_logo} height="26px" alt="Logo" />
					</Hidden>
					<Hidden smDown>
						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<InputBase
								placeholder="Search Route…"
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput
								}}
								onChange={handleChange}
								inputProps={{ 'aria-label': 'search' }}
								value={searchword}
								endAdornment={
									<InputAdornment position="end">
										{searchempty ? (
											<p />
										) : (
											<IconButton aria-label="cancel input" onMouseDown={handleClearSearch}>
												<CancelIcon fontSize="small" style={{ color: '#fff' }} />
											</IconButton>
										)}
									</InputAdornment>
								}
							/>
						</div>
					</Hidden>
					<Typography className={classes.title} />
					{chosensport === 'cycling' ? (
						<Hidden smDown>
							<Button
								onClick={props.handleClickOpenBadgeDialog}
								color="inherit"
								startIcon={<BadgeIcon />}
								endIcon={<ExpandMoreIcon />}
							>
								Badges
							</Button>
							<BadgeDialog
								openBadgeDialog={props.openBadgeDialog}
								setOpenBadgeDialog={props.setOpenBadgeDialog}
								handleClickCloseBadgeDialog={props.handleClickCloseBadgeDialog}
							/>

							<Button
								onClick={props.handleAchievementDrawerToggle}
								color="inherit"
								startIcon={<ProgressionIcon />}
								endIcon={<ExpandMoreIcon />}
							>
								Achievements
							</Button>
						</Hidden>
					) : (
						<Hidden smDown>
							<Button
								onClick={props.handleClickOpenBadgeDialogRun}
								color="inherit"
								startIcon={<BadgeIconRun />}
								endIcon={<ExpandMoreIcon />}
							>
								Badges
							</Button>
							<BadgeDialogRun
								openBadgeDialogRun={props.openBadgeDialogRun}
								setOpenBadgeDialogRun={props.setOpenBadgeDialogRun}
								handleClickCloseBadgeDialogRun={props.handleClickCloseBadgeDialogRun}
							/>

							<Button
								onClick={props.handleAchievementDrawerToggleRun}
								color="inherit"
								startIcon={<ProgressionIconRun />}
								endIcon={<ExpandMoreIcon />}
							>
								Achievements
							</Button>
						</Hidden>
					)}

					<IconButton color="inherit" onClick={handleClickOpenHelp}>
						<HelpOutlineIcon fontSize="large" />
					</IconButton>

					<Help openHelp={openHelp} setOpenHelp={setOpenHelp} handleClickCloseHelp={handleClickCloseHelp} />

					<AuthCheck
						fallback={
							<IconButton color="inherit" onClick={handleClickOpen}>
								<AccountCircle className={classes.small} />
							</IconButton>
						}
					>
						<IconButton color="inherit" onClick={handleClickOpen}>
							{user === null ? (
								<span>-</span>
							) : user.photoURL === null ? (
								<Avatar alt="Userletter" className={classes.avatar}>
									{user.displayName[0]}
								</Avatar>
							) : (
								<Avatar alt="Userpic" className={classes.small} src={user.photoURL} />
							)}
						</IconButton>
					</AuthCheck>

					<Dialog onClose={handleClose} aria-labelledby="auth" open={open}>
						<LogInOut />
						<Divider />
						<DialogActions>
							<Button onClick={handleClose} color="primary">
								Close
							</Button>
						</DialogActions>
					</Dialog>

					<IconButton color="inherit" onClick={handleClickmore}>
						<MoreIcon />
					</IconButton>
					<Menu
						id="simple-menu"
						anchorEl={anchorElmore}
						keepMounted
						open={Boolean(anchorElmore)}
						onClose={handleClosemore}
					>
						<MenuItem onClick={handleClickOpenAbout}>About</MenuItem>
						<About openAbout={openAbout} setOpenAbout={setOpenAbout} />
						<MenuItem onClick={handleClickOpenNews}>Changelog</MenuItem>
						<News openNews={openNews} setOpenNews={setOpenNews} />
						<MenuItem onClick={handleClickOpenContact}>Contact</MenuItem>
						<Contact openContact={openContact} setOpenContact={setOpenContact} />
						<MenuItem onClick={handleClickOpenDonate}>
							<FavoriteIcon fontSize="small" /> Donate <FavoriteIcon fontSize="small" />
						</MenuItem>
						<Donate openDonate={openDonate} setOpenDonate={setOpenDonate} />
						<MenuItem onClick={handleClickOpenPrivacyPolicy}>Privacy Policy</MenuItem>
						<PrivacyPolicy
							openPrivacyPolicy={openPrivacyPolicy}
							setOpenPrivacyPolicy={setOpenPrivacyPolicy}
						/>
						<MenuItem onClick={handleClickOpenToSCopyright}>ToS &amp; Copyright</MenuItem>
						<ToSCopyright openToSCopyright={openToSCopyright} setOpenToSCopyright={setOpenToSCopyright} />
					</Menu>
				</Toolbar>
			</AppBar>
			{chosensport === 'cycling' ? (
				<Drawer anchor="right" open={props.achievementOpen} onClose={props.handleAchievementDrawerToggle}>
					<AchievementDrawer classesName={classes.achievementdrawer} />
				</Drawer>
			) : (
				<Drawer anchor="right" open={props.achievementOpenRun} onClose={props.handleAchievementDrawerToggleRun}>
					<AchievementDrawerRun classesName={classes.achievementdrawer} />
				</Drawer>
			)}
		</div>
	);
}

export default memo(TopBar);
